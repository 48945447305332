.slide-down-navbar-section {
    --navbarSlideDownWidth: 100%;
    --navbarSlideDownHeight: 45px;

    width: 100%;
    height: var(--navbarSlideDownHeight);
    background-color: transparent;
    position: fixed;
    top: 0;
    z-index: 800;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    pointer-events: none;
}

.slide-down-navbar-layer {
    width: 100%;

    position: fixed;
    top: 0;
    left: 0;

    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: top 0.3s;

    pointer-events: visible;
}

.slide-down-navbar-container {
    width: 100%;

    display: grid;
    grid-template-columns: auto 1fr auto;

    padding-left: 10%;
    padding-right: 10%;

    z-index: 800;
}

.layout-layer1-menu-side-nav {
    width: 100%;
    height: 100%;

    position: absolute;
    top: 0;
    left: 0;

    display: flex;
    justify-content: center;
    align-items: center;
}

.layout-layer1-menu-left-item {
    width: 100%;
    background-color: transparent;

    display: flex;
    justify-content: center;
    align-items: center;
}

.layout-layer1-menu-center-item {
    width: 100%;
    background-color: transparent;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.layout-layer1-menu-right-item {
    width: 100%;
    background-color: transparent;
}

.layout-layer1-dropdown-menu {
    height: var(--navbarSlideDownHeight);
    
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    overflow: hidden;
}

.layout-layer1-dropdown-content {
    width: 100%;
    height: auto;

    visibility: hidden;

    display: flex;
    flex-direction: row;
    justify-content: center;
    position: absolute;

    top: 45px;
    left: 0;
    z-index: 700;
    background-color: transparent;
    
    overflow: hidden;
}

.layout-layer1-text-link {
    width: auto;
    height: var(--navbarSlideDownHeight);
  
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: var(--colorWhite);

    margin-top: 1px;
    margin-bottom: 5px;
  
    padding-left: 20px;
    padding-right: 20px;

    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;

    border-radius: 3px;

    overflow: hidden;
  }

.layout-layer1-dropdown-content a:hover {
    background-color:  #2490E3;
    color: #FFFFFF;

    border-radius: 5px;
}

/* Show the dropdown menu when the user moves the mouse over the dropdown button */
.layout-layer1-dropdown-menu:hover .layout-layer1-dropdown-content {
    visibility: visible;
}

.m-slide-down-navbar-logo {
    width: auto;
    height: 30px;
}

.m-link-navbar-text-item {
    text-decoration: none;
}

.layout-layer1-text-menu {
    font-size: 13px;
    color: black;

    padding-left: 15px;
    padding-right: 15px;
    padding-top: 10px;
    padding-bottom: 10px;

    background-color: transparent;
}

.layout-layer1-text-menu:hover {
    cursor: pointer;
    color: #2490E3;
}

/** responsive media screen */
/**
  320px — 480px: Mobile devices
  481px — 768px: iPads, Tablets
  769px — 1024px: Small screens, laptops
  1025px — 1200px: Desktops, large screens
  1201px and more —  Extra large screens, TV
 */


/*
@media only screen and (min-width : 320px) and (max-width : 480px) {
        
}

@media only screen and (min-width : 481px) and (max-width : 768px) {
        
}

@media only screen and (min-width : 769px) and (max-width : 1024px) {
        
}

@media only screen and (min-width : 1025px) and (max-width : 1200px) {
        
}

@media only screen and (min-width : 1201px) {
        
}
*/

@media only screen and (min-width : 320px) and (max-width : 480px) {
    .slide-down-navbar-section {
        width: 100%;
        z-index: 800;
    }

    .slide-down-navbar-layer {
        width: 100%;
        height: var(--navbarSlideDownHeight);
        background-color: white;
    }

    .layout-layer1-menu-side-nav {
        display: block;
    }

    .slide-down-navbar-container {
        width: 100%;

        justify-content: center;
        align-items: center;

        background-color: transparent;
    }

    .layout-layer1-menu-left-item {
        display: none;
    }

    .layout-layer1-menu-center-item {
        display: none;
    }

    .layout-layer1-menu-right-item {
        display: block;
        z-index: 500;
    }

    .m-link-navbar-text-item a {
        font-size: 11px;
        color: black;

        padding-left: 15px;
        padding-right: 15px;
    }

    .m-slide-down-navbar-logo {
        width: auto;
        height: 14px;
    }
}

@media only screen and (min-width : 481px) and (max-width : 768px) {
    .slide-down-navbar-section {
        width: 100%;
        z-index: 800;
    }

    .slide-down-navbar-container {
        display: grid;
        grid-template-columns: auto;

        justify-content: center;
        align-items: center;

        margin: auto;
    }

    .slide-down-navbar-layer {
        width: 100%;
        height: var(--navbarSlideDownHeight);
        background-color: white;
    }

    .layout-layer1-menu-side-nav {
        display: block;
    }

    .slide-down-navbar-container {
        width: 100%;

        justify-content: center;
        align-items: center;

        background-color: transparent;
    }

    .layout-layer1-menu-left-item {
        display: block;
        justify-content: center;
        align-items: center;

        background-color: transparent;
    }

    .layout-layer1-menu-center-item {
        display: none;
    }

    .layout-layer1-menu-right-item {
        display: none;
        z-index: 500;
    }

    .m-link-navbar-text-item a {
        font-size: 11px;
        color: black;

        padding-left: 15px;
        padding-right: 15px;
    }

    .m-slide-down-navbar-logo {
        width: auto;
        height: 14px;
    }
}

@media only screen and (min-width : 769px) and (max-width : 1024px) {
    .slide-down-navbar-section {
        width: 100%;
        z-index: 800;
    }

    .slide-down-navbar-container {
        width: 100%;

        display: grid;
        grid-template-columns: auto 1fr auto;

        padding-left: 10%;
        padding-right: 10%;

        z-index: 500;
    }

    .slide-down-navbar-layer {
        width: 100%;
        height: var(--navbarSlideDownHeight);
        background-color: white;
    }

    .layout-layer1-menu-side-nav {
        display: block;
    }

    .slide-down-navbar-container {
        width: 100%;

        justify-content: center;
        align-items: center;

        background-color: transparent;
    }

    .layout-layer1-menu-left-item {
        display: block;
        justify-content: center;
        align-items: center;

        background-color: transparent;
    }

    .layout-layer1-menu-center-item {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        background-color: transparent;
        z-index: 500;
    }

    .layout-layer1-menu-right-item {
        display: block;
        justify-content: center;
        align-items: center;

        background-color: transparent;
        z-index: 500;
    }

    .m-link-navbar-text-item a {
        font-size: 11px;
        color: black;

        padding-left: 15px;
        padding-right: 15px;
    }

    .m-slide-down-navbar-logo {
        width: auto;
        height: 14px;
    }
}

@media only screen and (min-width : 1025px) and (max-width : 1200px) {
    .slide-down-navbar-section {
        width: 100%;
        z-index: 800;
    }

    .slide-down-navbar-container {
        width: 100%;

        display: grid;
        grid-template-columns: auto 1fr auto;

        padding-left: 10%;
        padding-right: 10%;

        z-index: 500;
    }

    .slide-down-navbar-layer {
        width: 100%;
        height: var(--navbarSlideDownHeight);
        background-color: white;
    }

    .layout-layer1-menu-side-nav {
        display: block;
    }

    .slide-down-navbar-container {
        width: 100%;

        justify-content: center;
        align-items: center;

        background-color: transparent;
    }

    .layout-layer1-menu-left-item {
        display: block;
        justify-content: center;
        align-items: center;

        background-color: transparent;
    }

    .layout-layer1-menu-center-item {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        background-color: transparent;
        z-index: 500;
    }

    .layout-layer1-menu-right-item {
        display: block;
        justify-content: center;
        align-items: center;

        background-color: transparent;
        z-index: 500;
    }

    .m-link-navbar-text-item a {
        font-size: 11px;
        color: black;

        padding-left: 15px;
        padding-right: 15px;
    }

    .m-slide-down-navbar-logo {
        width: auto;
        height: 14px;
    }
}

@media only screen and (min-width : 1201px) {
    .slide-down-navbar-section {
        width: 100%;
        z-index: 800;
    }

    .slide-down-navbar-container {
        width: 100%;

        display: grid;
        grid-template-columns: auto 1fr auto;

        padding-left: 20%;
        padding-right: 20%;

        z-index: 500;
    }

    .slide-down-navbar-layer {
        width: 100%;
        height: var(--navbarSlideDownHeight);
        background-color: white;
    }

    .layout-layer1-menu-side-nav {
        display: block;
    }

    .slide-down-navbar-container {
        justify-content: center;
        align-items: center;

        background-color: transparent;
    }

    .layout-layer1-menu-left-item {
        display: block;
        justify-content: center;
        align-items: center;

        background-color: transparent;
    }

    .layout-layer1-menu-center-item {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        background-color: transparent;
        z-index: 500;
    }

    .layout-layer1-menu-right-item {
        display: block;
        justify-content: center;
        align-items: center;

        background-color: transparent;
        z-index: 500;
    }

    .m-link-navbar-text-item a {
        font-size: 13px;
        color: black;

        padding-left: 15px;
        padding-right: 15px;
    }

    .m-slide-down-navbar-logo {
        width: auto;
        height: 18px;
    }
}