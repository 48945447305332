.m-design-filter-section {
  --colorTextButtonNormal: #1E1E1E;
  --colorTextButtonOnHover: #2490E3;

  width: 100%;
  height: 100%;

  background-color: white;
}

.m-design-filter-container {
  width: 100%;
  height: 100%;
}

.m-design-filter-text-input-container {
  width: 100%;
  padding-left: 10%;
  padding-right: 10%;

  background-color: transparent;
}

.m-design-filter-text-input-top-container {
  width: 100%;

  display: grid;
  grid-template-columns: 1fr auto;
  background-color: transparent;
}

.m-design-filter-text-input-row {
  width: 100%;

  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;

  background-color: transparent;
}

.m-design-filter-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 10px;

  margin-top: 50px;
  margin-bottom: 50px;

  padding-left: 50px;
  padding-right: 50px;
}

.m-design-filter-row {
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;

  background-color: white;
  overflow: hidden;
}

.m-design-filter-card {
  width: 100%;
  height: 100%;

  background-color: transparent;
}

.m-design-filter-card-top-container {
  width: 100%;
  background-color: transparent;
}

.m-design-filter-card-bottom-container {
  width: 100%;

  text-align: left;
  background-color: white;
}

.m-design-filter-row-image {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.m-design-filter-tag-text {
  font-size: 12px;
  color: gray;
  text-transform: uppercase;
  margin-top: 8px;

  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  /* number of lines to show */
  line-clamp: 1;
  -webkit-box-orient: vertical;
}

.m-design-filter-title-text {
  font-size: 13px;
  color: black;
  margin-top: 8px;

  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.m-design-filter-description-text {
  font-size: 13px;
  color: black;
  margin-top: 8px;

  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  /* number of lines to show */
  line-clamp: 3;
  -webkit-box-orient: vertical;
}

.m-design-filter-button-read-more {
  background: transparent;
  border: 1px solid var(--colorPrimary);
  font-size: 13px;
  color: var(--colorPrimary);
  text-transform: lowercase;
  margin-top: 10px;

  padding-top: 4px;
  padding-bottom: 4px;
  padding-left: 10px;
  padding-right: 10px;

  border-radius: 5px;

  transition: 0.3s;
}

.m-design-filter-button-read-more:hover {
  cursor: pointer;
  background: var(--colorPrimary);
  color: var(--colorWhite);
}

.m-design-filter-clear-button-container {
  width: auto;

  display: flex;
  flex-direction: row;

  background-color: transparent;
}

.m-design-filter-button {
  width: auto;

  margin-left: 5px;

  border: 0px solid transparent;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;

  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 10px;
  padding-right: 10px;

  font-size: 13px;
  color: var(--colorTextButtonNormal);
  background-color: white;

  border-radius: 20px;

  transition: all 0.2s ease-in-out;
}

.m-design-filter-button:hover {
  cursor: pointer;
  color: var(--colorTextButtonOnHover);
  background-color: white;
}

.m-design-filter-buttonfa-button-icon {
  width: 15px;
  height: 15px;

  margin-right: 10px;
}

.m-design-filter-input {
  width: 100%;
  height: 60px;

  border-top-style: hidden;
  border-right-style: hidden;
  border-left-style: hidden;
  border-bottom-style: hidden;
  background-color: transparent;

  text-overflow: ellipsis;

  padding: 8px !important;

  box-sizing: border-box;
  border: 1px solid var(--colorGray);
}

.m-design-filter-input:focus {
  outline: none;
}

.m-design-filter-input-result {
  display: none;
  width: 100%;

  border-top-style: hidden;
  border-right-style: hidden;
  border-left-style: hidden;
  border-bottom-style: hidden;
  background-color: transparent;

  text-overflow: ellipsis;
  text-align: left;

  padding: 8px !important;

  /* set disable fake intercept touch event hover */
  pointer-events: none;
}

.m-design-filter-input-result:focus {
  outline: none;
}

.m-design-filter-button-clear-filter {
  width: 100%;

  border: 0px solid transparent;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;

  padding: 10px !important;

  font-size: 13px;
  color: var(--colorTextButtonNormal);
  background: transparent;

  transition: all 0.2s ease-in-out;
}

.m-design-filter-button-clear-filter:hover {
  cursor: pointer;
  color: var(--colorTextButtonOnHover);
}

/** responsive media screen */
/**
320px — 480px: Mobile devices
481px — 768px: iPads, Tablets
769px — 1024px: Small screens, laptops
1025px — 1200px: Desktops, large screens
1201px and more —  Extra large screens, TV
*/


/*
@media only screen and (min-width : 320px) and (max-width : 480px) {
      
}

@media only screen and (min-width : 481px) and (max-width : 768px) {
      
}

@media only screen and (min-width : 769px) and (max-width : 1024px) {
      
}

@media only screen and (min-width : 1025px) and (max-width : 1200px) {
      
}

@media only screen and (min-width : 1201px) {
      
}
*/

@media only screen and (min-width : 320px) and (max-width : 480px) {
  .m-design-filter-text-input-container {
      padding-left: 20px;
      padding-right: 20px;

      background-color: transparent;
  }

  .m-design-filter-text-input-top-container {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
  }

  .m-design-filter-grid {
      display: grid;
      grid-template-columns: repeat(1, 1fr);

      background-color: transparent;

      margin-top: 20px;
      margin-bottom: 20px;

      padding-left: 20px;
      padding-right: 20px;
  }

  .m-design-filter-input {
      height: 30px;
  }
}

@media only screen and (min-width : 481px) and (max-width : 768px) {
  .m-design-filter-text-input-container {
      padding-left: 20px;
      padding-right: 20px;

      background-color: transparent;
  }

  .m-design-filter-text-input-top-container {
      display: grid;
      grid-template-columns: 1fr auto;
  }

  .m-design-filter-grid {
      display: grid;
      grid-template-columns: repeat(1, 1fr);

      background-color: transparent;

      margin-top: 20px;
      margin-bottom: 20px;

      padding-left: 20px;
      padding-right: 20px;
  }

  .m-design-filter-input {
      height: 40px;
  }
}

@media only screen and (min-width : 769px) and (max-width : 1024px) {
  .m-design-filter-text-input-container {
      padding-left: 20px;
      padding-right: 20px;

      background-color: transparent;
  }

  .m-design-filter-text-input-top-container {
      display: grid;
      grid-template-columns: 1fr auto;
  }

  .m-design-filter-grid {
      display: grid;
      grid-template-columns: repeat(2, 1fr);

      background-color: transparent;

      margin-top: 20px;
      margin-bottom: 20px;

      padding-left: 20px;
      padding-right: 20px;
  }

  .m-design-filter-input {
      height: 40px;
  }
}

@media only screen and (min-width : 1025px) and (max-width : 1200px) {
  .m-design-filter-text-input-container {
      padding-left: 20px;
      padding-right: 20px;

      background-color: transparent;
  }

  .m-design-filter-text-input-top-container {
      display: grid;
      grid-template-columns: 1fr auto;
  }

  .m-design-filter-grid {
      display: grid;
      grid-template-columns: repeat(3, 1fr);

      background-color: transparent;

      margin-top: 20px;
      margin-bottom: 20px;

      padding-left: 20px;
      padding-right: 20px;
  }

  .m-design-filter-input {
      height: 60px;
  }
}

@media only screen and (min-width : 1201px) {
  .m-design-filter-text-input-container {
      padding-left: 50px;
      padding-right: 50px;

      background-color: transparent;
  }

  .m-design-filter-text-input-top-container {
      display: grid;
      grid-template-columns: 1fr auto;
  }

  .m-design-filter-grid {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 10px;
  
      margin-top: 50px;
      margin-bottom: 50px;
  
      padding-left: 50px;
      padding-right: 50px;
  }

  .m-design-filter-input {
      height: 60px;
  }
}