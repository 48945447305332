.chat-list-item {
    /** color res */
    --colorListOnlineIndicator: #2DB642;
    --colorListWhite: #FFFFFF;
    --colorListTransparent: transparent;
    --colorListPrimary: #2490E3;
    --colorListPrimaryDark: #1E1E1E;
    --colorListGray: #dfdfdf;
    --colorListBigHeader: #2DB642;
    --bgListTitleInfo: #F6F7F9;

    /** padding untuk list item */
    --paddingLeft: 5px;
    --paddingRight: 5px;
    --paddingTop: 5px;
    --paddingBottom: 5px;

    width: 100%;
    height: 100%;
}

.chat-list-item-container {
    width: 100%;
    height: 100%;
}

.list-chat-activity {
    display: grid;
    grid-template-columns: auto auto 1fr auto;
    grid-template-rows: 1fr;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    grid-gap: 0px;
    width: 100%;
    background-color: var(--colorListWhite);
    /** set rounded corner box untuk list chat */
    border-radius: 5px;
    /** set position untuk item */
    justify-content: center;
    align-items: center;
    text-align: center;
    /** set shadow bg */
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}

.list-chat-activity:hover {
    cursor: pointer;
    /**transform: translateY(5px);*/
    background-image: linear-gradient(90deg, #d6d5d3, #e4e4e4);
    transition: .3s ease-in;
}

.online-indicator {
    /** membuat indikator garis vertikal dengan warna */
    border-left: 3px solid var(--colorListOnlineIndicator);
    height: 100%;
    /** set rounded corner untuk indikator online */
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}

.profil-item {
    margin-left: 5px;
    /** set padding untuk list item */
    padding-top: var(--paddingTop);
    padding-bottom: var(--paddingBottom);
}

.icon:hover {}

.box-item {
    background-color: transparent;
    /** set margin untuk text chat activity */
    margin-left: 5px;
    margin-right: 5px;
    /** set padding untuk kontent text */
    padding-top: var(--paddingTop);
    padding-bottom: var(--paddingBottom);
}

.header-title-number {
    display: grid;
    grid-template-columns: auto auto 1fr;
    grid-template-rows: 1fr;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    grid-gap: 0px;
    width: 100%;
    /** set position center untuk item header title */
    justify-content: center;
    align-items: center;
    text-align: center;
}

.chat-list-title {
    font-size: 14px;
}

.box-item p {
    text-align: left;
}

.chat-list-message {
    font-size: 13px;
    margin-left: 5px;
}

.space {
    padding: 1px;
}

.time-stamp {
    font-size: 10px;
}

.icon-item {
    margin-right: 5px;
}