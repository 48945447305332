.our-service-view-all-section {
    width: 100%;
    height: auto;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    background-color: white;
}

.our-service-view-all-container {
    width: 100%;
    height: 100%;

    background-color: transparent;
}

/* Fading animation */
.fade {
    
    -webkit-animation: fadein 1s; /* Safari, Chrome and Opera > 12.1 */
    -moz-animation: fadein 1s; /* Firefox < 16 */
     -ms-animation: fadein 1s; /* Internet Explorer */
      -o-animation: fadein 1s; /* Opera < 12.1 */
         animation: fadein 1s;
}

/** reveal animation pages */
.reveal-card-1 {
    position: relative;
    transform: translateY(150px);
    opacity: 0.8;
    transition: 1s all ease;
}

.reveal-card-1.active-card-1 {
    transform: translateY(0);
    opacity: 1;
}

.reveal-card-2 {
    position: relative;
    transform: translateY(150px);
    opacity: 0.8;
    transition: 1s all ease;
}

.reveal-card-2.active-card-2 {
    transform: translateY(0);
    opacity: 1;
}

@keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Internet Explorer */
@-ms-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}