.portofolio-section {
    --colorPrimary: #2490E3;
    --colorPrimaryDark: #1E1E1E;
    --colorWhite: #FFFFFF;
    --colorGray: #dfdfdf;
    /** text size */
    --textAppearanceSmallest: 10px;
    --textAppearanceSmaller: 14px;
    --textAppearanceSmall: 16px;
    --textAppearanceMedium: 18px;
    --textAppearanceLarge: 22px;
    --globalTextSize: 18px;
    /** simen offset from main navigation menu */
    /** dimen navigation mobile screen */
    --navWidthMobileScreen: 100%;
    --navHeightMobileScreen: 35px;

    width: 100%;
    height: 100%;
}

.portofolio-container {
    width: 100%;
    height: auto;
    background-color: transparent;
}

.image-portofolio {
    width: 100%;
    height: auto;
}

.portofolio-row {
    display: -ms-flexbox;
    /* IE10 */
    display: flex;
    -ms-flex-wrap: wrap;
    /* IE10 */
    flex-wrap: wrap;
}

/* Create four equal columns that sits next to each other */
.portofolio-column {
    -ms-flex: 50%;
    /* IE10 */
    flex: 50%;
    max-width: 50%;
}

.portofolio-column img {
    display: block;
    width: 100%;
    height: auto;
}

.portofolio-column .img-container {
    position: relative;
    width: 100%;
    height: auto;
}

.img-overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    transition: .5s ease;
    background-color: #008CBA;
}

.img-overlay:hover .img-overlay {
    opacity: 1;
}

.img-text {
    color: white;
    font-size: 20px;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    text-align: center;
}

/** reveal animation pages */
/** reveal animation pages */
.reveal-card-1 {
    position: relative;
    transform: translateY(50px);
    opacity: 0;
    transition: 1s all ease;
}

.reveal-card-1.active-card-1 {
    transform: translateY(0);
    opacity: 1;
}

.reveal-card-2 {
    position: relative;
    transform: translateY(50px);
    opacity: 0;
    transition: 1s all ease;
}

.reveal-card-2.active-card-2 {
    transform: translateY(0);
    opacity: 1;
}

/**
  320px — 480px: Mobile devices
  481px — 768px: iPads, Tablets
  769px — 1024px: Small screens, laptops
  1025px — 1200px: Desktops, large screens
  1201px and more —  Extra large screens, TV
*/

@media only screen and (min-width : 320px) and (max-width : 480px) {

    .portofolio-row {
        display: -ms-flexbox;
        /* IE10 */
        display: flex;
        -ms-flex-wrap: wrap;
        /* IE10 */
        flex-wrap: wrap;
        flex-direction: column;
    }

    .portofolio-column {
        -ms-flex: 100%;
        /* IE10 */
        flex: 100%;
        max-width: 100%;
    }

    .column {
        -ms-flex: 100%;
        flex: 100%;
        max-width: 100%;
    }
}

@media only screen and (min-width : 481px) and (max-width : 768px) {
    .portofolio-row {
        display: -ms-flexbox;
        /* IE10 */
        display: flex;
        -ms-flex-wrap: wrap;
        /* IE10 */
        flex-wrap: wrap;
        flex-direction: column;
    }

    .portofolio-column {
        -ms-flex: 100%;
        /* IE10 */
        flex: 100%;
        max-width: 100%;
    }

    .column {
        -ms-flex: 100%;
        flex: 100%;
        max-width: 100%;
    }
}

@media only screen and (min-width : 769px) and (max-width : 1024px) {
    .portofolio-row {
        display: -ms-flexbox;
        /* IE10 */
        display: flex;
        -ms-flex-wrap: wrap;
        /* IE10 */
        flex-wrap: wrap;
    }

    .portofolio-column {
        -ms-flex: 50%;
        /* IE10 */
        flex: 50%;
        max-width: 50%;
    }

    .column {
        -ms-flex: 50%;
        flex: 50%;
        max-width: 50%;
    }
}

@media only screen and (min-width : 1025px) and (max-width : 1200px) {
    .portofolio-row {
        display: -ms-flexbox;
        /* IE10 */
        display: flex;
        -ms-flex-wrap: wrap;
        /* IE10 */
        flex-wrap: wrap;
    }

    .portofolio-column {
        -ms-flex: 50%;
        /* IE10 */
        flex: 50%;
        max-width: 50%;
    }

    .column {
        -ms-flex: 50%;
        flex: 50%;
        max-width: 50%;
    }
}

@media only screen and (min-width : 1201px) {
    .portofolio-row {
        display: -ms-flexbox;
        /* IE10 */
        display: flex;
        -ms-flex-wrap: wrap;
        /* IE10 */
        flex-wrap: wrap;
    }

    .portofolio-column {
        -ms-flex: 50%;
        /* IE10 */
        flex: 50%;
        max-width: 50%;
    }

    .column {
        -ms-flex: 50%;
        flex: 50%;
        max-width: 50%;
    }
}