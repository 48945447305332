.app-project-masterplan {
    --colorPrimary: #2490E3;
    --colorPrimaryDark: #1E1E1E;
    --colorWhite: #FFFFFF;
    --colorGray: #dfdfdf;

    width: 100%;
    height: auto;
    background-color: var(--colorWhite);
    /** fixed untuk gallery container */
    display: flex;
    flex-direction: column;
    align-items: center;

    /** margin top dari nav header */
    margin-top: 70px;
}

.project-masterplan-nav-header {
    width: 100%;
    height: auto;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 700;
}

.project-masterplan-container {
    width: 100%;
    height: 100%;
}

.project-masterplan-bottom-nav {
    width: 100%;
    height: 100%;
}