* {
  box-sizing: border-box;
  margin: 0;
}

.portofolio-slider-container {
  /** color res */
  --colorTransparent: transparent;
  --colorPrimary: #2490E3;
  --colorPrimaryDark: #1E1E1E;
  --colorWhite: #FFFFFF;
  --colorGray: #dfdfdf;

  --heights: 100vh;
  --widths: 100%;
  /** text size */
  --textAppearanceSmallest: 10px;
  --textAppearanceSmaller: 16px;
  --textAppearanceSmall: 18px;
  --textAppearanceMedium: 22px;
  --textAppearanceLargest: 32px;
  --textAppearanceLarger: 48px;
  --textAppearanceLarge: 82px;
  --globalTextSize: 18px;

  height: var(--heights);
  width: var(--widths);
  
  position: relative;
  margin: auto;
  overflow: hidden;
}

.portofolio-active {
  display: inline-block;
  /** animate smooth fade in */
  /** animate smooth fade in */
  animation-name: fade;
  animation-duration: 1.5s;
}

.portofolio-inactive {
  display: none;
}

.portofolio-slides {
  height: var(--heights);
  width: var(--widths);
  position: relative;
}

.portofolio-slide-image {
  width: 100%;
  height: auto;
  position: absolute;
  object-fit: cover;
}

.portofolio-slide-title,
.portofolio-slide-text {
  width: 100%;
  height: 100%;
  color: white;
  font-size: 50px;
  position: absolute;
  text-align: center;
  top: 80%;
  z-index: 10;
  /** animate fade in group description container*/
  opacity: 0;
  animation: fadeInText 0.7s cubic-bezier(0.175, 0.885, 0.32, 1.275) both;
}

.portofolio-slide-text {
  top: 88%;
  font-size: 2rem;
  /** animate fade in text*/
  animation: fadeInText 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275) both;
	animation-delay: 0.3s;
}

.portofolio-prev,
.portofolio-next {
  color: transparent;
  cursor: pointer;
  z-index: 100;
  position: absolute;
  top: 50%;
  width: auto;
  padding: 1rem;
  margin-top: -3rem;
  font-size: 40px;
  font-weight: bold;
  border-radius: 0px 5px 5px 0px;
}

.portofolio-slider-container:hover .portofolio-prev,.portofolio-slider-container:hover .portofolio-next {
  color: black
}

.portofolio-slider-container:hover .portofolio-prev:hover,
.portofolio-slider-container:hover .portofolio-next:hover {
  color: white;
  background-color: rgba(0, 0, 0, 0.6);
  transition: all 0.5s ease-in;
}

.portofolio-next {
  right: 0;
  border-radius: 5px 0px 0px 5px;
}

.portofolio-all-dots {
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  top: 95%;
  justify-content: center;
  z-index: 200;
}

.portofolio-dot {
  cursor: pointer;
  height: 0.5rem;
  width: 0.5rem;
  margin: 0px 3px;
  background-color: transparent;
  /* background-color: rgba(0, 0, 0, 0.3); */
  border-radius: 50%;
  display: inline-block;
  transition: background-color 0.5s ease;
}

.portofolio-slider-container:hover .m-dot:hover {
  background-color: rgba(255, 255, 255, 0.5);
}

.portofolio-slider-container:hover .m-dot{
  background-color: rgba(0, 0, 0, 0.3);
}
.portofolio-slider-container:hover .portofolio-active-dot{
  background-color: rgba(255, 255, 255, 0.5);
}

.portofolio-play-pause {
  color: black;
}

@keyframes smoothzoom {
  from {
    transform: scale(1);
  }

  to {
    transform: scale(1.05)
  }
}

/** smooth animate fade in */
@keyframes fade {
  from {opacity: .4} 
  to {opacity: 1}
}

/* Keyframe for animation  */
@keyframes fadeInGroupContainer {
  0% {
     opacity: 0;
     transform: translate3d(0, -10%, 0);
   }
   100% {
     text-shadow: none;
     opacity: 1;
     transform: translate3d(0, 0, 0);
   }
 }
 
 @keyframes fadeInText {
  0% {
     opacity: 0;
     transform: scale(0.99);
   }
   100% {
     transform: scale(1);
     opacity: 1;
   }
 }

/**
  320px — 480px: Mobile devices
  481px — 768px: iPads, Tablets
  769px — 1024px: Small screens, laptops
  1025px — 1200px: Desktops, large screens
  1201px and more —  Extra large screens, TV
 */
@media only screen and (min-width : 320px) and (max-width : 480px) {
  .portofolio-slider-container {
    height: 100%;
    width: 100%;
    position: relative;
    overflow: hidden;
    background-color: transparent;
  }
  
  .portofolio-active {
    display: block;
  }
  
  .portofolio-inactive {
    display: none;
  }
  
  .portofolio-slides {
    height: 100%;
    width: 100%;
    position: relative;
  }
  
  .portofolio-slide-image {
    width: 100%;
    height: 100%;
    position: relative;
    object-fit: contain;
    background-size: cover;
  }
  
  .portofolio-slide-title,
  .portofolio-slide-text {
    width: 100%;
    height: 100%;
    color: white;
    font-size: var(--textAppearanceLargest);
    position: absolute;
    text-align: center;
    top: 70%;
    z-index: 10;
  }
  
  .portofolio-slide-text {
    top: 88%;
    font-size: var(--textAppearanceSmaller);
  }
  
  .portofolio-prev,
  .portofolio-next {
    color: transparent;
    cursor: pointer;
    z-index: 100;
    position: absolute;
    top: 50%;
    width: auto;
    padding: 1rem;
    margin-top: -3rem;
    font-size: 25px;
    font-weight: bold;
    border-radius: 0px 5px 5px 0px;
  }
  
  .portofolio-slider-container:hover .portofolio-prev,
  .portofolio-slider-container:hover .portofolio-next {
    color: black
  }
  
  .portofolio-slider-container:hover .portofolio-prev:hover,
  .portofolio-slider-container:hover .portofolio-next:hover {
    color: white;
    background-color: rgba(0, 0, 0, 0.6);
    transition: all 0.5s ease-in;
  }
  
  .portofolio-next {
    right: 0;
    border-radius: 5px 0px 0px 5px;
  }
  
  .portofolio-all-dots {
    width: 100%;
    height: auto;
    position: absolute;
    display: flex;
    top: 95%;
    justify-content: center;
    z-index: 200;
    background-color: transparent;
  }
  
  .portofolio-dot {
    cursor: pointer;
    height: 0.4rem;
    width: 0.4rem;
    margin: 0px 3px;
    background-color: transparent;
    /* background-color: rgba(0, 0, 0, 0.3); */
    border-radius: 50%;
    display: inline-block;
  }
  
  .portofolio-slider-container:hover .portofolio-dot:hover {
    background-color: rgba(255, 255, 255, 0.5);
  }
  
  /* .portofolio-active-dot {
    background-color: rgba(255, 255, 255, 0.5);
  } */
  
  .portofolio-slider-container:hover .portofolio-dot {
    background-color: rgba(0, 0, 0, 0.3);
  }
  
  .portofolio-slider-container:hover .portofolio-active-dot {
    background-color: rgba(255, 255, 255, 0.5);
  }
  
  .portofolio-play-pause {
    color: black;
  }
}

@media only screen and (min-width : 481px) and (max-width : 768px) {
  .portofolio-slider-container {
    height: 100%;
    width: 100%;
    position: relative;
    overflow: hidden;
    background-color: transparent;
  }
  
  .portofolio-active {
    display: block;
  }
  
  .portofolio-inactive {
    display: none;
  }
  
  .portofolio-slides {
    height: 100%;
    width: 100%;
    position: relative;
  }
  
  .portofolio-slide-image {
    width: 100%;
    height: 100%;
    position: relative;
    object-fit: contain;
    background-size: cover;
  }
  
  .portofolio-slide-title,
  .portofolio-slide-text {
    width: 100%;
    height: 100%;
    color: white;
    font-size: var(--textAppearanceLargest);
    position: absolute;
    text-align: center;
    top: 80%;
    z-index: 10;
  }
  
  .portofolio-slide-text {
    top: 90%;
    font-size: var(--textAppearanceSmaller);
  }
  
  .portofolio-prev,
  .portofolio-next {
    color: transparent;
    cursor: pointer;
    z-index: 100;
    position: absolute;
    top: 50%;
    width: auto;
    padding: 1rem;
    margin-top: -3rem;
    font-size: 25px;
    font-weight: bold;
    border-radius: 0px 5px 5px 0px;
  }
  
  .portofolio-slider-container:hover .portofolio-prev,
  .portofolio-slider-container:hover .portofolio-next {
    color: black
  }
  
  .portofolio-slider-container:hover .portofolio-prev:hover,
  .portofolio-slider-container:hover .portofolio-next:hover {
    color: white;
    background-color: rgba(0, 0, 0, 0.6);
    transition: all 0.5s ease-in;
  }
  
  .portofolio-next {
    right: 0;
    border-radius: 5px 0px 0px 5px;
  }
  
  .portofolio-all-dots {
    width: 100%;
    height: auto;
    position: absolute;
    display: flex;
    top: 95%;
    justify-content: center;
    z-index: 200;
    background-color: transparent;
  }
  
  .portofolio-dot {
    cursor: pointer;
    height: 0.4rem;
    width: 0.4rem;
    margin: 0px 3px;
    background-color: transparent;
    /* background-color: rgba(0, 0, 0, 0.3); */
    border-radius: 50%;
    display: inline-block;
  }
  
  .portofolio-slider-container:hover .portofolio-dot:hover {
    background-color: rgba(255, 255, 255, 0.5);
  }
  
  /* .portofolio-active-dot {
    background-color: rgba(255, 255, 255, 0.5);
  } */
  
  .portofolio-slider-container:hover .portofolio-dot {
    background-color: rgba(0, 0, 0, 0.3);
  }
  
  .portofolio-slider-container:hover .portofolio-active-dot {
    background-color: rgba(255, 255, 255, 0.5);
  }
  
  .portofolio-play-pause {
    color: black;
  }
}

@media only screen and (min-width : 769px) and (max-width : 1024px) {
  .portofolio-slider-container {
    height: 100%;
    width: 100%;
    position: relative;
    overflow: hidden;
    background-color: transparent;
  }
  
  .portofolio-active {
    display: block;
  }
  
  .portofolio-inactive {
    display: none;
  }
  
  .portofolio-slides {
    height: 100%;
    width: 100%;
    position: relative;
  }
  
  .portofolio-slide-image {
    width: 100%;
    height: 100%;
    position: relative;
    object-fit: contain;
    background-size: cover;
  }
  
  .portofolio-slide-title,
  .portofolio-slide-text {
    width: 100%;
    height: 100%;
    color: white;
    font-size: var(--textAppearanceLargest);
    position: absolute;
    text-align: center;
    top: 81%;
    z-index: 10;
  }
  
  .portofolio-slide-text {
    top: 90%;
    font-size: var(--textAppearanceSmaller);
  }
  
  .portofolio-prev,
  .portofolio-next {
    color: transparent;
    cursor: pointer;
    z-index: 100;
    position: absolute;
    top: 50%;
    width: auto;
    padding: 1rem;
    margin-top: -3rem;
    font-size: 25px;
    font-weight: bold;
    border-radius: 0px 5px 5px 0px;
  }
  
  .portofolio-slider-container:hover .portofolio-prev,
  .portofolio-slider-container:hover .portofolio-next {
    color: black
  }
  
  .portofolio-slider-container:hover .portofolio-prev:hover,
  .portofolio-slider-container:hover .portofolio-next:hover {
    color: white;
    background-color: rgba(0, 0, 0, 0.6);
    transition: all 0.5s ease-in;
  }
  
  .portofolio-next {
    right: 0;
    border-radius: 5px 0px 0px 5px;
  }
  
  .portofolio-all-dots {
    width: 100%;
    height: auto;
    position: absolute;
    display: flex;
    top: 95%;
    justify-content: center;
    z-index: 200;
    background-color: transparent;
  }
  
  .portofolio-dot {
    cursor: pointer;
    height: 0.4rem;
    width: 0.4rem;
    margin: 0px 3px;
    background-color: transparent;
    /* background-color: rgba(0, 0, 0, 0.3); */
    border-radius: 50%;
    display: inline-block;
  }
  
  .portofolio-slider-container:hover .portofolio-dot:hover {
    background-color: rgba(255, 255, 255, 0.5);
  }
  
  /* .portofolio-active-dot {
    background-color: rgba(255, 255, 255, 0.5);
  } */
  
  .portofolio-slider-container:hover .portofolio-dot {
    background-color: rgba(0, 0, 0, 0.3);
  }
  
  .portofolio-slider-container:hover .portofolio-active-dot {
    background-color: rgba(255, 255, 255, 0.5);
  }
  
  .portofolio-play-pause {
    color: black;
  }
}

@media only screen and (min-width : 1025px) and (max-width : 1200px) {
  .portofolio-slide-title,
  .portofolio-slide-text {
    width: 100%;
    height: 100%;
    color: white;
    font-size: var(--textAppearanceLarger);
    position: absolute;
    text-align: left;
    /** position text description */
    margin-left: 20%;
    top: 82%;
    z-index: 10;
  }
  
  .portofolio-slide-text {
    top: 92%;
    font-size: var(--textAppearanceSmaller);
  }

  .portofolio-all-dots {
    width: 100%;
    height: auto;
    position: absolute;
    display: flex;
    top: 95%;
    justify-content: left;
    /** position dot image carousel navigation */
    margin-left: 20%;
    z-index: 200;
    background-color: transparent;
  }

  .portofolio-dot {
    cursor: pointer;
    height: 0.3rem;
    width: 20px;
    margin: 0px 5px;
    background-color: transparent;
    border-radius: 5%;
    display: inline-block;
  }

  .portofolio-slider-container:hover .portofolio-dot:hover {
    background-color: rgba(36, 144, 227, 0.9)
  }
  
  .portofolio-slider-container:hover .portofolio-dot {
    background-color: rgba(0, 0, 0, 0.3);
  }
  
  .portofolio-slider-container:hover .portofolio-active-dot {
    background-color: rgba(255, 255, 255, 0.5);
  }
}

@media only screen and (min-width : 1201px) {
  .portofolio-slide-title,
  .portofolio-slide-text {
    width: 100%;
    height: 100%;
    color: white;
    font-size: var(--textAppearanceLarge);
    position: absolute;
    text-align: left;
    /** position text description */
    margin-left: 20%;
    top: 77%;
    z-index: 10;
  }
  
  .portofolio-slide-text {
    top: 90%;
    font-size: var(--textAppearanceMedium);
  }

  .portofolio-all-dots {
    width: 100%;
    height: auto;
    position: absolute;
    display: flex;
    top: 95%;
    justify-content: left;
    /** position dot image carousel navigation */
    margin-left: 20%;
    z-index: 200;
    background-color: transparent;
  }

  .portofolio-dot {
    cursor: pointer;
    height: 0.3rem;
    width: 20px;
    margin: 0px 5px;
    background-color: transparent;
    border-radius: 5%;
    display: inline-block;
  }

  .portofolio-slider-container:hover .portofolio-dot:hover {
    background-color: rgba(36, 144, 227, 0.9)
  }
  
  .portofolio-slider-container:hover .portofolio-dot {
    background-color: rgba(0, 0, 0, 0.3);
  }
  
  .portofolio-slider-container:hover .portofolio-active-dot {
    background-color: rgba(255, 255, 255, 0.5);
  }
}