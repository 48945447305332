.error-page-section {
    width: 100%;
    height: 100vh;
}

.error-page-container {
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: transparent;
}

.error-page-center-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: left;

    background-color: transparent;
    padding: 10px;
}

.error-page-title {
    font-size: 2.5rem;
    color: black;
    text-align: center;
}

.error-page-sub-title {
    font-size: 16px;
    color: black;
    text-align: center;
}

.error-page-button {
    width: 100%;
    height: 100%;
    
    background: transparent;
    border: 1px solid #2490E3;
    text-align: center;
    font-size: 14px;
    color: #2490E3;
    text-transform: uppercase;
    margin-top: 20px;

    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 10px;
    padding-right: 10px;

    border-radius: 5px;

    transition: 0.3s;
}

.error-page-button:hover {
    cursor: pointer;
    background: #2490E3;
    color: white;
}