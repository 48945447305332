.contact-us {
    /** color res */
    --colorTransparent: transparent;
    --colorPrimary: #2490E3;
    --colorPrimaryDark: #1E1E1E;
    --colorWhite: #FFFFFF;
    --colorGray: #dfdfdf;

    width: 100%;
    height: 100vh;
}

.contact-us .container {
    width: 80%;
    height: 100%;

    background-color: transparent;
    /** set margin layout */
    /** set position center */
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.bg {
    /* The image used */
    background-image: url("../assets/images/project/rumah/FAWN_HOUSE/fawn-house.jpg");
    /* Full height */
    width: auto;
    height: 100%;
    object-fit: cover;
    /* Center and scale the image nicely */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.bg-overlay {
    position: absolute;
    width: 100%;
    height: 100%;

    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    background-color: rgba(0, 0, 0, 0.3);
    -webkit-backdrop-filter: blur(3px);
    backdrop-filter: blur(3px);
}

.base-header {
    width: 100%;
    height: auto;

    max-width: 100%;
    max-height: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    /** set rounded corner bg */
    border-radius: 20px;
    /** set background blur */
    background-color: rgba(255, 255, 255, 0.3);
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
    /** padding header */
    padding: 20px;
    /** set overflow-y sroll true */
    overflow-y: auto;
}

.grid-container {
    width: 100%;
    display: grid;
    grid-template-columns: 50% auto;
    grid-template-rows: 1fr;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    grid-gap: 10px;
}

.contact-form {
    width: 100%;
    height: auto;
    max-width: 100%;
    max-height: 100%;
    background-color: transparent;
    /** set padding */
    padding-left: 5px;
    padding-right: 5px;
}

.input-container {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 5px;
    grid-row-gap: 0px;
    grid-gap: 10px;
}

.left-input {
    background-color: var(--colorTransparent);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: left;
}

.right-input {
    background-color: var(--colorTransparent);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: left;
}

.child-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: left;
}

.child-item a {
    font-size: 14px;
    /** set padding */
    padding-top: 5px;
    padding-bottom: 5px;
    /** set warna text */
    color: var(--colorWhite);
}

.text-promp-input-area {
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: left;
}

.text-promp {
    display: none;
    margin-left: 5px;
    color: var(--colorPrimary);
}

.message-input {
    /** space untuk form message dari atas */
    margin-top: 0px;
    background-color: transparent;
    /** set style layout */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: left;
}

.message-input a {
    font-size: 14px;
    /** set padding */
    padding-top: 5px;
    padding-bottom: 5px;
    /** set warna text */
    color: var(--colorWhite);
}

.button-send-message {
    background-color: var(--colorWhite);
    /* Green */
    border: none;
    color: var(--colorPrimaryDark);
    padding: 5px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    /** set margin top */
    margin-top: 5px;
    /** set rounded corner bg */
    border-radius: 5px;
    /** set border box */
    border: 1px solid var(--colorWhite);
}

.button-send-message:hover {
    cursor: pointer;
    color: var(--colorWhite);
    background-color: var(--colorPrimary);
    /** set border box */
    border: 1px solid var(--colorPrimary);
}

.button-send-message a {
    font-size: 14px;
}

/*
.input-textarea {
    width: 100%;
    height: 100px;

    min-width: 100%;
    min-height: 200px;

    max-width: 100%;
    max-height: 400px;
}
*/

.info-form {
    width: 100%;
    height: auto;
    background-color: transparent;
}

.contact-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: left;
}

.contact-info-container {
    /** background contact info di kanan */
    background-color: var(--colorTransparent);
    /** set padding */
    padding: 10px;
    /** set rounded corner */
    border-radius: 5px;
}

.contact-us-h1 {
    font-size: 20px;
    padding-top: 6px;
    padding-bottom: 6px;
    /** set text color */
    color: var(--colorWhite);
}

.contact-us-h2 {
    background-color: transparent;
    margin-bottom: 20px;
    font-size: 2rem;
    text-align: center;
    /** text color */
    color: var(--colorWhite);
}

.list-item {
    display: flex;
    justify-content: left;
    align-items: center;
    /** background contact info */
    background-color: transparent;
    /** set margin untuk list item */
    margin-top: 5px;
    margin-bottom: 5px;
}

.list-item a {
    font-size: 13px;
    /** set padding */
    padding-top: 5px;
    padding-bottom: 5px;
    /** set warna text */
    color: var(--colorWhite);
    margin-left: 10px;
}

.list-icon {
    width: auto;
    height: 25px;
    /** set padding untuk bg */
    padding: 5px;
    background-color: var(--colorPrimary);
    /** background bulat untuk icon contact info */
    border-radius: 50px;
}

.social-media-link {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    /** set margin top */
    margin-top: 10px;
}

.mlink-icon {
    width: auto;
    height: 25px;
    /** set padding untuk bg */
    padding: 5px;
    /** set margin */
    margin-left: 5px;
    margin-right: 5px;
    background-color: var(--colorGray);
    /** background bulat untuk icon contact info */
    border-radius: 50px;
}

.mlink-icon:hover {
    cursor: pointer;
}

.contact-us-input {
    width: 100%;
    height: 35px;
    /** set background */
    background-color: var(--colorWhite);
    /** set padding input */
    padding-left: 10px;
    padding-right: 10px;
    /** set background input type */
    border-radius: 3px;
    /** set animation input type */
    -webkit-transition: width .35s ease-in-out;
    transition: width .35s ease-in-out;
    /** set box sizing */
    box-sizing: border-box;
    border: 1px solid var(--colorGray);
}

.contact-us-input:focus {
    background-color: var(--colorWhite);
    color: var(--colorPrimaryDark);
}

.contact-us-textarea {
    width: 100%;
    height: 300px;

    padding: 10px 10px;
    box-sizing: border-box;
    border: 1px solid var(--colorGray);
    border-radius: 5px;
    background-color: var(--colorWhite);
    font-size: 14px;
    resize: none;
}

.contact-us-textarea:focus {
    background-color: var(--colorWhite);
    color: var(--colorPrimaryDark);
}

/* Fading animation */
.fade {
    -webkit-animation: fadein 1s; /* Safari, Chrome and Opera > 12.1 */
    -moz-animation: fadein 1s; /* Firefox < 16 */
     -ms-animation: fadein 1s; /* Internet Explorer */
      -o-animation: fadein 1s; /* Opera < 12.1 */
         animation: fadein 1s;
}

@keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Internet Explorer */
@-ms-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/** responsive media screen */
/**
  320px — 480px: Mobile devices
  481px — 768px: iPads, Tablets
  769px — 1024px: Small screens, laptops
  1025px — 1200px: Desktops, large screens
  1201px and more —  Extra large screens, TV
 */


/*
@media only screen and (min-width : 320px) and (max-width : 480px) {
        
}

@media only screen and (min-width : 481px) and (max-width : 768px) {
        
}

@media only screen and (min-width : 769px) and (max-width : 1024px) {
        
}

@media only screen and (min-width : 1025px) and (max-width : 1200px) {
        
}

@media only screen and (min-width : 1201px) {
        
}
*/

@media only screen and (min-width : 320px) and (max-width : 480px) {
    .contact-us .container {
        width: 100%;
        height: 100%;

        background-color: transparent;
    }

    .contact-us {
        width: 100%;
        height: 100%;
    }

    .base-header {
        width: 100%;
        height: 100%;
        border-radius: 0px;
    }

    .grid-container {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        grid-template-rows: 1fr;
        grid-column-gap: 0px;
        grid-row-gap: 0px;
        grid-gap: 10px;
    }

    .contact-form {
        width: 100%;
        height: 100%;
        padding-left: 5px;
        padding-right: 5px;
    }

    .input-container {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        grid-template-rows: 1fr;
        grid-column-gap: 5px;
        grid-row-gap: 0px;
        grid-gap: 10px;
    }

    .child-item a {
        font-size: 14px;
        padding-top: 5px;
        padding-bottom: 5px;
        color: var(--colorWhite);
    }

    .text-promp {
        margin-left: 5px;
        color: var(--colorPrimary);
    }

    .list-item a {
        font-size: 13px;
        padding-top: 5px;
        padding-bottom: 5px;
        color: var(--colorWhite);
        margin-left: 10px;
    }

    .button-send-message {
        width: 100%;
        height: auto;
        margin-top: 20px;
    }

    .contact-us-h1 {
        font-size: 18px;
        padding-top: 6px;
        padding-bottom: 6px;
        /** set text color */
        color: var(--colorWhite);
    }

    .contact-us-h2 {
        background-color: transparent;
        margin-bottom: 20px;
        font-size: 22px;
        text-align: center;
        /** text color */
        color: var(--colorWhite);
    }
}

@media only screen and (min-width : 481px) and (max-width : 768px) {
    .contact-us .container {
        width: 100%;
        height: 100%;

        background-color: transparent;
    }

    .contact-us {
        width: 100%;
        height: 100%;
    }

    .base-header {
        width: 100%;
        height: 100%;
        border-radius: 0px;
    }

    .grid-container {
        width: 100%;
        display: grid;
        grid-template-columns: 50% auto;
        grid-template-rows: 1fr;
        grid-column-gap: 0px;
        grid-row-gap: 0px;
        grid-gap: 10px;
    }

    .contact-form {
        width: 100%;
        height: 100%;
        padding-left: 5px;
        padding-right: 5px;
    }

    .input-container {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        grid-template-rows: 1fr;
        grid-column-gap: 5px;
        grid-row-gap: 0px;
        grid-gap: 10px;
    }

    .child-item a {
        font-size: 14px;
        padding-top: 5px;
        padding-bottom: 5px;
        color: var(--colorWhite);
    }

    .text-promp {
        margin-left: 5px;
        color: var(--colorPrimary);
    }

    .list-item a {
        font-size: 13px;
        padding-top: 5px;
        padding-bottom: 5px;
        color: var(--colorWhite);
        margin-left: 10px;
    }

    .button-send-message {
        width: 100%;
        height: auto;
        margin-top: 20px;
    }

    .contact-us-h1 {
        font-size: 18px;
        padding-top: 6px;
        padding-bottom: 6px;
        /** set text color */
        color: var(--colorWhite);
    }

    .contact-us-h2 {
        background-color: transparent;
        margin-bottom: 20px;
        font-size: 22px;
        text-align: center;
        /** text color */
        color: var(--colorWhite);
    }
}

@media only screen and (min-width : 769px) and (max-width : 1024px) {
    .contact-us .container {
        width: 100%;
        height: 100vh;

        background-color: transparent;
    }

    .contact-us {
        width: 100%;
        height: 100%;
    }

    .base-header {
        width: 100%;
        height: 100%;
        border-radius: 0px;
    }

    .grid-container {
        width: 100%;
        display: grid;
        grid-template-columns: 50% auto;
        grid-template-rows: 1fr;
        grid-column-gap: 0px;
        grid-row-gap: 0px;
        grid-gap: 10px;
    }

    .contact-form {
        width: 100%;
        height: 100%;
        padding-left: 5px;
        padding-right: 5px;
    }

    .input-container {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        grid-template-rows: 1fr;
        grid-column-gap: 5px;
        grid-row-gap: 0px;
        grid-gap: 10px;
    }

    .child-item a {
        font-size: 14px;
        padding-top: 5px;
        padding-bottom: 5px;
        color: var(--colorWhite);
    }

    .text-promp {
        margin-left: 5px;
        color: var(--colorPrimary);
    }

    .list-item a {
        font-size: 13px;
        padding-top: 5px;
        padding-bottom: 5px;
        color: var(--colorWhite);
        margin-left: 10px;
    }

    .button-send-message {
        width: 100%;
        height: auto;
        margin-top: 20px;
    }

    .contact-us-h1 {
        font-size: 18px;
        padding-top: 6px;
        padding-bottom: 6px;
        /** set text color */
        color: var(--colorWhite);
    }

    .contact-us-h2 {
        background-color: transparent;
        margin-bottom: 20px;
        font-size: 22px;
        text-align: center;
        /** text color */
        color: var(--colorWhite);
    }
}

@media only screen and (min-width : 1025px) and (max-width : 1200px) {
    .contact-us .container {
        width: 100%;
        height: 100%;

        background-color: transparent;
    }

    .contact-us {
        width: 100%;
        height: 100%;
    }

    .base-header {
        width: 100%;
        height: 100%;
        border-radius: 0px;
    }

    .grid-container {
        width: 100%;
        display: grid;
        grid-template-columns: 50% auto;
        grid-template-rows: 1fr;
        grid-column-gap: 0px;
        grid-row-gap: 0px;
        grid-gap: 10px;
    }

    .contact-form {
        width: 100%;
        height: 100%;
        padding-left: 5px;
        padding-right: 5px;
    }

    .input-container {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        grid-template-rows: 1fr;
        grid-column-gap: 5px;
        grid-row-gap: 0px;
        grid-gap: 10px;
    }

    .child-item a {
        font-size: 14px;
        padding-top: 5px;
        padding-bottom: 5px;
        color: var(--colorWhite);
    }

    .text-promp {
        margin-left: 5px;
        color: var(--colorPrimary);
    }

    .list-item a {
        font-size: 13px;
        padding-top: 5px;
        padding-bottom: 5px;
        color: var(--colorWhite);
        margin-left: 10px;
    }

    .button-send-message {
        width: 100%;
        height: auto;
        margin-top: 20px;
    }

    .contact-us-h1 {
        font-size: 18px;
        padding-top: 6px;
        padding-bottom: 6px;
        /** set text color */
        color: var(--colorWhite);
    }

    .contact-us-h2 {
        background-color: transparent;
        margin-bottom: 20px;
        font-size: 22px;
        text-align: center;
        /** text color */
        color: var(--colorWhite);
    }

    .bg {
        width: 100%;
        height: 100vh;
        object-fit: cover;
    }
}

@media only screen and (min-width : 1201px) {
    .contact-us .container {
        width: 70%;
        height: 100%;

        background-color: transparent;
    }

    .contact-us {
        width: 100%;
        height: 100%;
    }

    .base-header {
        width: 100%;
        height: 100%;
        border-radius: 0px;
    }

    .grid-container {
        width: 100%;
        display: grid;
        grid-template-columns: 50% auto;
        grid-template-rows: 1fr;
        grid-column-gap: 0px;
        grid-row-gap: 0px;
        grid-gap: 10px;
    }

    .contact-form {
        width: 100%;
        height: 100%;
        padding-left: 5px;
        padding-right: 5px;
    }

    .input-container {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        grid-template-rows: 1fr;
        grid-column-gap: 5px;
        grid-row-gap: 0px;
        grid-gap: 10px;
    }

    .child-item a {
        font-size: 14px;
        padding-top: 5px;
        padding-bottom: 5px;
        color: var(--colorWhite);
    }

    .text-promp {
        margin-left: 5px;
        color: var(--colorPrimary);
    }

    .list-item a {
        font-size: 13px;
        padding-top: 5px;
        padding-bottom: 5px;
        color: var(--colorWhite);
        margin-left: 10px;
    }

    .button-send-message {
        width: 100%;
        height: auto;
        margin-top: 20px;
    }

    .contact-us-h1 {
        font-size: 18px;
        padding-top: 6px;
        padding-bottom: 6px;
        /** set text color */
        color: var(--colorWhite);
    }

    .contact-us-h2 {
        background-color: transparent;
        margin-bottom: 20px;
        font-size: 22px;
        text-align: left;
        /** text color */
        color: var(--colorWhite);
    }

    .bg {
        width: 100%;
        height: 100vh;
        object-fit: cover;
    }
}