body,
html {
  margin: 0;
  font-family: "system-ui", Helvetica, sans-serif, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
    font-family: system-ui;
    src: url('./components/assets/fonts/sf_pro_display_font/sf-pro-text-regular.ttf');
}
