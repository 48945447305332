.floating-back-to-home-button {
    position: fixed;
    bottom: 10px;
    left: 10px;
    border-radius: 0px;
    background-color: #096bb5;
    color: white;
    border: none;
    
    padding: 10px;
    cursor: pointer;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
    transition: background-color 0.3s, transform 0.3s;
    z-index: 500;
  }
  
  .floating-back-to-home-button:hover {
    background-color: #555;
    transform: scale(1.1);
  }
  
  .f-back-icon {
    font-size: 18px;
  }