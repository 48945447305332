.hero-background-rn-section {
    --containerOffset: 17%;
    width: 100%;
    height: 100vh;

    background-color: white;
}

.hero-background-rn-image {
    background-image: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), url("../../../../../assets/images/project/rumah/GW_HOUSE/grand-wisata-02.jpg");
    width: 100%;
    height: 100vh;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    object-fit: cover;
    position: relative;
}

.hero-background-rn-hero-text {
    width: 100%;
    height: 100%;

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: transparent;

    display: flex;
    justify-content: center;
    align-items: center;
}

.hero-background-rn-center {
    width: 100%;
    height: auto;

    display: flex;
    flex-direction: column;

    margin-left: var(--containerOffset);
    margin-right: var(--containerOffset);

    background-color: transparent;
}

.hero-background-rn-center-floating-nav {

    width: 100%;
    height: auto;

    padding-left: var(--containerOffset);
    padding-right: var(--containerOffset);

    background-color: transparent;

    position: absolute;
    top: 0;
    left: 0;

    display: flex;
    justify-content: center;
    align-items: center;
}

.hero-background-rn-intro {
    margin-top: 40px;

    font-family: 'system-ui', Arial, Helvetica;
    font-size: 16px;
    color: white;
    padding-right: 50%;

    background-color: transparent;

    /** animate text */
    animation: loadH1 1s ease;
}

.hero-background-rn-text-opacity {
    margin-top: 40px;

    font-family: 'system-ui', Arial, Helvetica;
    font-size: 16px;
    color: white;
    opacity: 0.8;

    /** animate text */
    animation: loadImg 1s ease;
}

.hero-background-rn-button-action {
    opacity: 0.8;
    text-transform: uppercase;
    background: transparent;
    width: 300px;
    height: auto;

    border: 1px solid white;
    display: inline-block;
    float: none;
    text-align: center;
    padding: 10px !important;

    font-family: 'system-ui', Arial, Helvetica;
    font-size: 14px;
    color: #fff;

    border-radius: 5px;
    overflow: hidden;

    transition: all 0.2s ease-in-out;
    animation: loadH1 1s ease;
}

.hero-background-rn-button-action:hover {
    cursor: pointer;
    color: #fff;
    background: rgba(255, 255, 255, 0.2);
}

.hero-background-rn-button-action:focus {
    animation: 2s ease;
    animation-iteration-count: 1;
}

@font-face {
	font-family: system-ui;
	src: url('../../../../../../components/assets/fonts/sf_pro_display_font/sf-pro-text-medium.ttf');
}

/** keyframe transition pages */
@keyframes loadText {
    0% {
        transform: translateY(150px);
        opacity: 0;
    }

    100% {
        transform: translateY(0);
        opacity: 1;
    }
}

@keyframes loadH1 {
    0% {
        opacity: 0;
        transform: translateY(3em);
    }

    30%,
    50% {
        opacity: 0;
        transform: translateY(3em);
    }

    100% {
        opacity: 1;
        transform: none;
    }
}

@keyframes loadImg {
    0% {
        opacity: 0;
        transform: translateY(100%);
    }

    100% {
        transform: none;
    }
}

/** responsive media screen */
/**
  320px — 480px: Mobile devices
  481px — 768px: iPads, Tablets
  769px — 1024px: Small screens, laptops
  1025px — 1200px: Desktops, large screens
  1201px and more —  Extra large screens, TV
 */


/*
@media only screen and (min-width : 320px) and (max-width : 480px) {
        
}

@media only screen and (min-width : 481px) and (max-width : 768px) {
        
}

@media only screen and (min-width : 769px) and (max-width : 1024px) {
        
}

@media only screen and (min-width : 1025px) and (max-width : 1200px) {
        
}

@media only screen and (min-width : 1201px) {
        
}
*/

@media only screen and (min-width : 320px) and (max-width : 480px) {
    .hero-background-rn-intro {
        font-size: 14px;
        color: white;
        padding-right: 1%;
    }
}

@media only screen and (min-width : 481px) and (max-width : 768px) {
        
}

@media only screen and (min-width : 769px) and (max-width : 1024px) {
        
}

@media only screen and (min-width : 1025px) and (max-width : 1200px) {
        
}

@media only screen and (min-width : 1201px) {
        
}