.testimonial-section {
    --testimonialPadding: 100px;

    width: 100%;
    height: auto;

    background-color: white;

    overflow: hidden;
}

.testimonial-text-intro {
    width: 100%;

    background-color: transparent;

    font-size: 18px;
    color: black;

    padding-left: 10px;
    padding-right: 10px;
    padding-top: 10px;
    padding-bottom: 10px;

    text-align: center;
}

.testimonial-container {
    width: 100%;
    height: 100%;

    padding-left: var(--testimonialPadding);
    padding-right: var(--testimonialPadding);
    padding-top: 10px;
    padding-bottom: 10px;

    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 5px;
    grid-row-gap: 0px;
    grid-gap: 10px;

    background-color: transparent;
}

.testimonial-card {
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    background-color: white;

    padding: 20px;
    position: relative;

    transition: transform .2s;
    overflow: hidden;
}

.testimonial-card:hover {
    cursor: pointer;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.testimonial-text-header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    margin-top: 10px;
    margin-bottom: 10px;
}

.testimonial-text-header a {
    font-family: 'system-ui', Arial, Helvetica;
    font-size: 14px;
    color: black;
}

.testimonial-text-header h1 {
    font-family: 'system-ui', Arial, Helvetica;
    font-size: 40px;
    color: black;
}

.testimonial-top-container {
    width: 100%;
    height: 100%;

    padding-left: 20px;
    padding-right: 20px;

    overflow-y: scroll;
    overflow: auto;

    font-family: 'system-ui', Arial, Helvetica;
    font-size: 14px;
    color: black;
    text-overflow: ellipsis;
}

.testimonial-bottom-container {
    width: 100%;
    height: auto;

    margin-top: 10px;

    padding-left: 20px;
    padding-right: 20px;
}

.testimonial-row {
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;

    background-color: transparent;

    font-family: 'system-ui', Arial, Helvetica;
    font-size: 14px;
    color: black;
    text-overflow: ellipsis;
}

.testimonial-profil-info {
    width: 50px;
    height: 50px;

    padding: 5px;
    background-color: transparent;
}

.testimonial-text-name {
    margin-left: 10px;

    font-family: 'system-ui', Arial, Helvetica;
    font-size: 14px;
    color: black;
}

.reveal-card {
    position: relative;
    transform: translateY(150px);
    opacity: 0;
    transition: 1s all ease;
}

.reveal-card.active-card {
    transform: translateY(0);
    opacity: 1;
}

@font-face {
	font-family: system-ui;
	src: url('../../components/assets/fonts/sf_pro_display_font/sf-pro-text-medium.ttf');
}

/** responsive media screen */
/**
  320px — 480px: Mobile devices
  481px — 768px: iPads, Tablets
  769px — 1024px: Small screens, laptops
  1025px — 1200px: Desktops, large screens
  1201px and more —  Extra large screens, TV
 */


/*
@media only screen and (min-width : 320px) and (max-width : 480px) {
        
}

@media only screen and (min-width : 481px) and (max-width : 768px) {
        
}

@media only screen and (min-width : 769px) and (max-width : 1024px) {
        
}

@media only screen and (min-width : 1025px) and (max-width : 1200px) {
        
}

@media only screen and (min-width : 1201px) {
        
}
*/

@media only screen and (min-width : 320px) and (max-width : 480px) {
    .testimonial-text-intro {
        width: 100%;

        background-color: transparent;

        font-size: 16px;
        color: black;

        padding-left: 5px;
        padding-right: 5px;

        margin-top: 5px;
        margin-bottom: 5px;
        text-align: center;
    }

    .testimonial-container {
        width: 100%;
        height: 100%;

        padding-left: 20px;
        padding-right: 20px;
        padding-top: 20px;
        padding-bottom: 20px;

        display: grid;
        grid-template-columns: repeat(1, 1fr);
        grid-template-rows: 1fr;
        grid-column-gap: 5px;
        grid-row-gap: 0px;
        grid-gap: 10px;

        background-color: transparent;
    }

    .testimonial-card {
        width: 100%;
        height: 100%;
        background-color: white;
        border-radius: 5px;
        padding: 10px;
    }

    .testimonial-text-header a {
        font-size: 14px;
        color: black;
    }

    .testimonial-text-header h1 {
        font-size: 30px;
        color: black;
    }
}

@media only screen and (min-width : 481px) and (max-width : 768px) {
    .testimonial-text-intro {
        width: 100%;

        background-color: transparent;

        font-size: 16px;
        color: black;

        padding-left: 5px;
        padding-right: 5px;

        margin-top: 5px;
        margin-bottom: 5px;
        text-align: center;
    }

    .testimonial-container {
        width: 100%;
        height: 100%;

        padding-left: 20px;
        padding-right: 20px;
        padding-top: 20px;
        padding-bottom: 20px;

        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: 1fr;
        grid-column-gap: 5px;
        grid-row-gap: 0px;
        grid-gap: 10px;

        background-color: transparent;
    }

    .testimonial-card {
        width: 100%;
        height: 100%;
        background-color: white;
        border-radius: 5px;
        padding: 10px;
    }

    .testimonial-text-header a {
        font-size: 14px;
        color: black;
    }

    .testimonial-text-header h1 {
        font-size: 30px;
        color: black;
    }
}

@media only screen and (min-width : 769px) and (max-width : 1024px) {
    .testimonial-text-intro {
        width: 100%;

        background-color: transparent;

        font-size: 16px;
        color: black;

        padding-left: 5px;
        padding-right: 5px;

        margin-top: 5px;
        margin-bottom: 5px;
        text-align: center;
    }

    .testimonial-container {
        width: 100%;
        height: 100%;

        padding-left: 20px;
        padding-right: 20px;
        padding-top: 20px;
        padding-bottom: 20px;

        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: 1fr;
        grid-column-gap: 5px;
        grid-row-gap: 0px;
        grid-gap: 10px;

        background-color: transparent;
    }

    .testimonial-card {
        width: 100%;
        height: 100%;
        background-color: white;
        border-radius: 5px;
        padding: 10px;
    }

    .testimonial-text-header a {
        font-size: 14px;
        color: black;
    }

    .testimonial-text-header h1 {
        font-size: 30px;
        color: black;
    }
}

@media only screen and (min-width : 1025px) and (max-width : 1200px) {
    .testimonial-text-intro {
        width: 100%;

        background-color: transparent;

        font-size: 16px;
        color: black;

        padding-left: 5px;
        padding-right: 5px;

        margin-top: 5px;
        margin-bottom: 5px;
        text-align: center;
    }

    .testimonial-container {
        width: 100%;
        height: 100%;

        padding-left: 30px;
        padding-right: 30px;
        padding-top: 30px;
        padding-bottom: 30px;

        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-template-rows: 1fr;
        grid-column-gap: 5px;
        grid-row-gap: 0px;
        grid-gap: 10px;

        background-color: transparent;
    }

    .testimonial-card {
        width: 100%;
        height: 100%;
        background-color: white;
        border-radius: 5px;
        padding: 10px;
    }

    .testimonial-text-header a {
        font-size: 14px;
        color: black;
    }

    .testimonial-text-header h1 {
        font-size: 30px;
        color: black;
    }
}

@media only screen and (min-width : 1201px) {
    .testimonial-text-intro {
        width: 100%;

        background-color: transparent;

        font-size: 24px;
        color: black;

        padding-left: 5px;
        padding-right: 5px;

        margin-top: 5px;
        margin-bottom: 5px;
        text-align: center;
    }

    .testimonial-container {
        width: 100%;
        height: 100%;

        padding-left: 10%;
        padding-right: 10%;
        padding-top: 50px;
        padding-bottom: 50px;

        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-template-rows: 1fr;
        grid-column-gap: 5px;
        grid-row-gap: 0px;
        grid-gap: 10px;

        background-color: transparent;
    }

    .testimonial-card {
        width: 100%;
        height: 100%;
        background-color: white;
        border-radius: 5px;
        padding: 10px;
    }

    .testimonial-text-header a {
        font-size: 14px;
        color: black;
    }

    .testimonial-text-header h1 {
        font-size: 30px;
        color: black;
    }
}