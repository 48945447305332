:root {
    --textIndicatorDark: #1E1E1E;
    --textIndicatorWhite: #FFFFFF;
    --textIndicatorHover: #2490E3;
    --textIndicatorHoverPressed: #FFFFFF;
}

.scroll-to-top {
    width: auto;
    height: auto;

    position: fixed;
    left: 95%;
    bottom: 3%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    background-color: white;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
    
    z-index: 500;
    overflow: hidden;

    border-radius: 30%;
}

.scroll-to-top:hover {
    background-color: #096bb5;
    transform: scale(1.1);
  }

.scroll-to-top-indicator {
    width: auto;
    height: auto;

    padding-left: 10px;
    padding-right: 10px;
    padding-top: 10px;
    padding-bottom: 10px;

    background-color: transparent;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.scroll-to-top-indicator a {
    font-size: 16px;
    color: var(--textIndicatorDark);
    text-align: center;
}

.scroll-to-top-indicator a:hover {
    color: var(--textIndicatorHoverPressed);
    cursor: pointer;
}

/** responsive media screen */
/**
  320px — 480px: Mobile devices
  481px — 768px: iPads, Tablets
  769px — 1024px: Small screens, laptops
  1025px — 1200px: Desktops, large screens
  1201px and more —  Extra large screens, TV
 */


/*
@media only screen and (min-width : 320px) and (max-width : 480px) {
        
}

@media only screen and (min-width : 481px) and (max-width : 768px) {
        
}

@media only screen and (min-width : 769px) and (max-width : 1024px) {
        
}

@media only screen and (min-width : 1025px) and (max-width : 1200px) {
        
}

@media only screen and (min-width : 1201px) {
        
}
*/

/** Phone */
@media only screen and (min-width : 320px) and (max-width : 480px) {
    .scroll-to-top {
        left: 75%;
        bottom: 3%;
    }
}

/** Tablet */
@media only screen and (min-width : 481px) and (max-width : 768px) {
    .scroll-to-top {
        left: 80%;
        bottom: 3%;
    }
}

/** Tablet Horizontal */
@media only screen and (min-width : 769px) and (max-width : 1024px) {
    .scroll-to-top {
        left: 85%;
        bottom: 3%;
    }
}

/** Laptop */
@media only screen and (min-width : 1025px) and (max-width : 1200px) {
    .scroll-to-top {
        left: 90%;
        bottom: 4%;
    }
}

/** PC Dekstop */
@media only screen and (min-width : 1201px) {
    .scroll-to-top {
        left: 90%;
        bottom: 4%;
    }
}
