.our-service-section {
    --colorButton: #F1EDDE;
    --colorButtonBorder: #EAEAEA;
    --colorButtonBorderLine: #EFF5F5;
    --colorButtonOnHover: #2490E3;
    --colorButtonText: #2490E3;

    width: 100%;
    height: 100%;

    background-color: white;
}

.our-service-container {
    width: 100%;
    height: 100%;

    padding-left: 15%;
    padding-right: 15%;

    padding-top: 20px;
    padding-bottom: 20px;

    background-color: transparent;
}

.our-service-intro {
    width: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    
    text-align: left;

    background-color: white;
    
    padding-left: 20px;
    padding-right: 20px;
}

.our-service-grid {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 50px;

    margin-top: 40px;
}

.our-service-row {
    width: 100%;
    background-color: white;

    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-column: 1fr auto;

    text-align: left;

    transition: 0.5s ease;
    padding-top: 50px;
    padding-bottom: 50px;

    padding-left: 20px;
    padding-right: 20px;

    overflow: hidden;

    border-radius: 5px;
}

.our-service-row:hover {
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    transform: scale(1.02);
    background-color: #EEEBE3;
    cursor: pointer;
}

.our-service-row-item {
    width: 100%;
    height: 100%;

    background-color: transparent;

    display: flex;
    flex-direction: column;
}

.our-service-row-thumb-image {
    width: 50px;
    height: 50px;
}

.our-service-row-bottom-container {
    width: 100%;

    padding-top: 20px;
    padding-bottom: 20px;
    
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
}

.our-service-row-text-header {
    font-size: 18px;
    color: #3D4149;
    text-transform: uppercase;
}

.our-service-row-a {
    font-size: 14px;
    color: #3D4149;
    text-transform: capitalize;

    padding-top: 20px;
    padding-bottom: 20px;
}

.our-service-row-text-link {
    text-decoration: none;
}

.our-service-row-button-action {
    background: transparent;
    width: 100%;
    height: auto;

    border: 1px solid var(--colorButtonOnHover);
    display: inline-block;
    float: none;
    text-align: center;
    padding: 8px !important;

    font-family: 'system-ui', Arial, Helvetica;
    font-size: 14px;
    color: var(--colorButtonText);

    border-radius: 5px;
    overflow: hidden;

    transition: all 0.4s ease-in-out;
}

.our-service-row-button-action:hover {
    cursor: pointer;
    color: white;
    background: var(--colorButtonOnHover);
}

.our-service-row-button-action:focus {
    animation-iteration-count: 1;
}

.our-service-row-button-action-view-all {
    background: var(--colorButtonText);
    width: auto;
    height: auto;

    border: 1px solid var(--colorButtonOnHover);
    display: inline-block;
    float: none;
    text-align: center;
    
    padding-left: 50px;
    padding-right: 50px;
    padding-top: 8px;
    padding-bottom: 8px;

    font-family: 'system-ui', Arial, Helvetica;
    font-size: 14px;
    color: white;

    border-radius: 5px;
    overflow: hidden;

    transition: all 0.4s ease-in-out;
}

.our-service-row-button-action-view-all:hover {
    cursor: pointer;
    color: white;
    background: #3877a7;
}

.our-service-row-button-action-view-all:focus {
    animation-iteration-count: 1;
}

/** reveal animation pages */
.reveal-card-1 {
    position: relative;
    transform: translateY(150px);
    opacity: 0;
    transition: 1s all ease;
}

.reveal-card-1.active-card-1 {
    transform: translateY(0);
    opacity: 1;
}

.reveal-card-2 {
    position: relative;
    transform: translateY(150px);
    opacity: 0;
    transition: 1s all ease;
}

.reveal-card-2.active-card-2 {
    transform: translateY(0);
    opacity: 1;
}

@font-face {
    font-family: system-ui;
    src: url('../../../../assets/fonts/sf_pro_display_font/sf-pro-text-medium.ttf');
}

/* Fading animation */
.fade {
    -webkit-animation: fadein 1s; /* Safari, Chrome and Opera > 12.1 */
    -moz-animation: fadein 1s; /* Firefox < 16 */
     -ms-animation: fadein 1s; /* Internet Explorer */
      -o-animation: fadein 1s; /* Opera < 12.1 */
         animation: fadein 1s;
}

@keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Internet Explorer */
@-ms-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/** responsive media screen */
/**
  320px — 480px: Mobile devices
  481px — 768px: iPads, Tablets
  769px — 1024px: Small screens, laptops
  1025px — 1200px: Desktops, large screens
  1201px and more —  Extra large screens, TV
 */


/*
@media only screen and (min-width : 320px) and (max-width : 480px) {
        
}

@media only screen and (min-width : 481px) and (max-width : 768px) {
        
}

@media only screen and (min-width : 769px) and (max-width : 1024px) {
        
}

@media only screen and (min-width : 1025px) and (max-width : 1200px) {
        
}

@media only screen and (min-width : 1201px) {
        
}
*/

@media only screen and (min-width : 320px) and (max-width : 480px) {
    .our-service-container {
        width: 100%;
        height: 100%;
    
        padding-left: 5%;
        padding-right: 5%;
    
        padding-top: 10px;
        padding-bottom: 10px;
    
        background-color: transparent;
    }

    .our-service-grid {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        grid-gap: 10px;
    }
    
    .our-service-row {
        grid-template-columns: repeat(1, 1fr);
        grid-column: 1fr auto;
    }
}

@media only screen and (min-width : 481px) and (max-width : 768px) {
    .our-service-container {
        width: 100%;
        height: 100%;
    
        padding-left: 5%;
        padding-right: 5%;
    
        padding-top: 10px;
        padding-bottom: 10px;
    
        background-color: transparent;
    }

    .our-service-grid {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 20px;
    }
    
    .our-service-row {
        grid-template-columns: repeat(1, 1fr);
        grid-column: 1fr auto;
    }
}

@media only screen and (min-width : 769px) and (max-width : 1024px) {
    .our-service-container {
        width: 100%;
        height: 100%;
    
        padding-left: 5%;
        padding-right: 5%;
    
        padding-top: 10px;
        padding-bottom: 10px;
    
        background-color: transparent;
    }

    .our-service-grid {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 20px;
    }
    
    .our-service-row {
        grid-template-columns: repeat(1, 1fr);
        grid-column: 1fr auto;
    }
}

@media only screen and (min-width : 1025px) and (max-width : 1200px) {
    .our-service-container {
        width: 100%;
        height: 100%;
    
        padding-left: 5%;
        padding-right: 5%;
    
        padding-top: 10px;
        padding-bottom: 10px;
    
        background-color: transparent;
    }

    .our-service-grid {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 10px;
    }
    
    .our-service-row {
        grid-template-columns: repeat(1, 1fr);
        grid-column: 1fr auto;
    }
}

@media only screen and (min-width : 1201px) {
    .our-service-grid {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 50px;
    
        margin-top: 40px;
    }
    
    .our-service-row {
        width: 100%;
        background-color: white;
    
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        grid-column: 1fr auto;
    
        text-align: left;
    
        transition: 0.5s ease;
        padding-top: 50px;
        padding-bottom: 50px;
    
        padding-left: 20px;
        padding-right: 20px;
    
        overflow: hidden;
    }    
}