.detail-blog-section {
    width: 100%;
    height: 100vh;

    background-color: white;
}

.detail-blog-container {
    background-color: white;

    padding-left: 100px;
    padding-right: 100px;
    padding-top: 40px;
    padding-bottom: 40px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.detail-blog-container p {
    font-size: 14px;
    color: black;
}

.detail-blog-container h1 {
    font-size: 22px;
    color: black;
}


.detail-blog-big-image {
    width: 100%;
    height: auto;

    object-fit: cover;
}

.detail-blog-image-container {
    width: 80%;
    height: 100%;

    background-color: transparent;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.detail-blog-image-footer {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-gap: 10px;

    background-color: transparent;

    padding-top: 20px;
    padding-bottom: 20px;
}

.detail-blog-image-footer a {
    font-size: 12px;
    color: black;

    padding-left: 10px;
    padding-right: 10px;
    padding-top: 3px;
    padding-bottom: 3px;

    text-align: left;

    background-color: rgb(235, 235, 235);
    border-radius: 50px;
}

.detail-blog-text-description {
    width: 100%;
    height: 100%;

    padding-left: 10%;
    padding-right: 10%;
}

/** responsive media screen */
/**
  320px — 480px: Mobile devices
  481px — 768px: iPads, Tablets
  769px — 1024px: Small screens, laptops
  1025px — 1200px: Desktops, large screens
  1201px and more —  Extra large screens, TV
 */


/*
@media only screen and (min-width : 320px) and (max-width : 480px) {
        
}

@media only screen and (min-width : 481px) and (max-width : 768px) {
        
}

@media only screen and (min-width : 769px) and (max-width : 1024px) {
        
}

@media only screen and (min-width : 1025px) and (max-width : 1200px) {
        
}

@media only screen and (min-width : 1201px) {
        
}
*/

@media only screen and (min-width : 320px) and (max-width : 480px) {
    .detail-blog-container {
        background-color: white;

        padding-left: 10px;
        padding-right: 10px;
        padding-bottom: 40px;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .detail-blog-image-container {
        width: 100%;
        height: 100%;

        background-color: transparent;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .detail-blog-text-description {
        width: 100%;
        height: 100%;

        padding-left: 10px;
        padding-right: 10px;
    }

    .detail-blog-image-footer {
        width: 100%;

        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 5px;
        background-color: transparent;

        padding-top: 10px;
        padding-bottom: 10px;
    }

    .detail-blog-container p {
        font-size: 12px;
        color: black;
    }

    .detail-blog-image-footer a {
        font-size: 7px;
        color: black;

        padding-left: 5px;
        padding-right: 5px;
        padding-top: 5px;
        padding-bottom: 5px;

        text-align: left;

        background-color: rgb(235, 235, 235);
        border-radius: 50px;
    }
}

@media only screen and (min-width : 481px) and (max-width : 768px) {

}

@media only screen and (min-width : 769px) and (max-width : 1024px) {

}

@media only screen and (min-width : 1025px) and (max-width : 1200px) {

}

@media only screen and (min-width : 1201px) {

}