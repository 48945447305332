.free-design-section {
  --textAppearanceSmallest: 13px;
  --textAppearanceSmaller: 14px;
  --textAppearanceSmall: 16px;
  --textAppearanceMedium: 18px;
  --textAppearanceLargest: 22px;
  --textAppearanceLarger: 44px;
  --textAppearanceLarge: 88px;
  --globalTextSize: 18px;

  width: 100%;
  height: 100vh;
  background-color: transparent;
}

.free-design-container {
  width: 100%;
  height: 100%;
  /* Use "linear-gradient" to add a darken background effect to the image (photographer.jpg). This will make the text easier to read */
  background-image: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url("../assets/images/project/masjid/MASJID_DARUSSALAM/masjid-pnf-resolusi-1920x1080.jpg");
  /* Position and center the image to scale nicely on all screens */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;

  background-color: transparent;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

/* Place text in the middle of the image */
.free-design-hero-text {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: left;

  padding-left: 20px;
  padding-right: 20px;
}

.free-design-hero-text-h1 {
  font-family: 'system-ui', Arial, Helvetica;
  font-size: 18px;
  color: white;

  /** animate text slide up */
  transform: translateY(0);
  animation: loadH1 1s ease;
}

.free-design-hero-text-p {
  font-family: 'system-ui', Arial, Helvetica;
  font-size: 14px;
  color: white;

  /** animate text slide up */
  transform: translateY(0);
  animation: loadH1 1s ease;
}

.free-design-text-link {
  color: white;
  text-decoration: none;
}

.free-design-text-link:hover {
  color: #0288D1;
}

.free-design-button-action {
  background: transparent;
  width: auto;
  height: auto;

  border: 1px solid white;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  float: none;
  text-align: center;
  padding: 8px !important;

  font-family: 'system-ui', Arial, Helvetica;
  font-size: 14px;
  color: #fff;

  border-radius: 50px;
  overflow: hidden;

  transition: all 0.2s ease-in-out;
}

.free-design-button-action:hover {
  cursor: pointer;
  color: #fff;
  background: rgba(255, 255, 255, 0.2);
}

.free-design-button-action:focus {
  animation: swing 1s ease;
  animation-iteration-count: 1;
}

.fa-button-icon {
  width: 30px;
  height: 30px;

  margin-left: 30px;
}

/* Fading animation */
.fade {
  -webkit-animation: fadein 1s;
  /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 1s;
  /* Firefox < 16 */
  -ms-animation: fadein 1s;
  /* Internet Explorer */
  -o-animation: fadein 1s;
  /* Opera < 12.1 */
  animation: fadein 1s;
}

.free-design-scroll-indicator-container {
  width: 100%;
  height: auto;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background-color: transparent;

  position: absolute;
  bottom: 10px;

  /** order content to top layout*/
  z-index: 100;
}

.slide-container-free-design-text {
  font-size: var(--textAppearanceSmall);
  color: white;
  text-align: center;
}

.slide-container-free-design-img {
  width: 40px;
  height: 40px;
  margin-top: 5px;
  padding: 5px;
  /** animate scroll indicator */
  -webkit-animation: fadeinout 4s linear forwards infinite;
  animation: fadeinout 4s linear forwards infinite;
}

@keyframes loadH1 {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }

  30%,
  50% {
    opacity: 0;
    transform: translateY(20px);
  }

  100% {
    opacity: 1;
    transform: none;
  }
}

@keyframes loadImg {
  0% {
    opacity: 0;
    transform: translateY(100%);
  }

  100% {
    transform: none;
  }
}

@keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

/* Internet Explorer */
@-ms-keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes swing {
  15% {
    transform: translateX(5px);
  }

  30% {
    transform: translateX(-5px);
  }

  50% {
    transform: translateX(3px);
  }

  65% {
    transform: translateX(-3px);
  }

  80% {
    transform: translateX(2px);
  }

  100% {
    transform: translateX(0);
  }
}


/** responsive media screen */
/**
  320px — 480px: Mobile devices
  481px — 768px: iPads, Tablets
  769px — 1024px: Small screens, laptops
  1025px — 1200px: Desktops, large screens
  1201px and more —  Extra large screens, TV
 */


/*
@media only screen and (min-width : 320px) and (max-width : 480px) {
        
}

@media only screen and (min-width : 481px) and (max-width : 768px) {
        
}

@media only screen and (min-width : 769px) and (max-width : 1024px) {
        
}

@media only screen and (min-width : 1025px) and (max-width : 1200px) {
        
}

@media only screen and (min-width : 1201px) {
        
}
*/

@media only screen and (min-width : 320px) and (max-width : 480px) {
  .free-design-hero-text {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: left;

    padding-left: 20px;
    padding-right: 20px;
  }

  .free-design-hero-text-h1 {
    font-size: 18px;
    color: white;
  }

  .free-design-hero-text-p {
    font-size: 13px;
    color: white;
  }

  .free-design-button-action {
    font-size: 13px;
    color: #fff;
    text-align: left;
  }

  .fa-button-icon {
    width: 25px;
    height: 25px;
  
    margin-left: 30px;
  }

  .free-design-scroll-indicator-container {
    display: flex;
  }

  .slide-container-free-design-text {
    font-size: var(--textAppearanceSmallest);
  }
}

@media only screen and (min-width : 481px) and (max-width : 768px) {
  .free-design-hero-text {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: left;

    padding-left: 50px;
    padding-right: 50px;
  }

  .free-design-hero-text-h1 {
    font-size: 18px;
    color: white;
  }

  .free-design-hero-text-p {
    font-size: 14px;
    color: white;
  }

  .free-design-button-action {
    font-size: 14px;
    color: #fff;
  }

  .fa-button-icon {
    width: 30px;
    height: 30px;
  
    margin-left: 30px;
  }

  .free-design-scroll-indicator-container {
    display: flex;
  }
  .slide-container-free-design-text {
    font-size: var(--textAppearanceSmall);
  }
}

@media only screen and (min-width : 769px) and (max-width : 1024px) {
  .free-design-hero-text {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: left;

    padding-left: 50px;
    padding-right: 50px;
  }

  .free-design-hero-text-h1 {
    font-size: 18px;
    color: white;
  }

  .free-design-hero-text-p {
    font-size: 14px;
    color: white;
  }

  .free-design-button-action {
    font-size: 14px;
    color: #fff;
  }

  .fa-button-icon {
    width: 30px;
    height: 30px;
  
    margin-left: 30px;
  }

  .free-design-scroll-indicator-container {
    display: flex;
  }

  .slide-container-free-design-text {
    font-size: var(--textAppearanceMedium);
  }
}

@media only screen and (min-width : 1025px) and (max-width : 1200px) {
  .free-design-hero-text {
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: left;

    padding-left: 20px;
    padding-right: 20px;
  }

  .free-design-hero-text-h1 {
    font-size: 18px;
    color: white;
  }

  .free-design-hero-text-p {
    font-size: 14px;
    color: white;
  }

  .free-design-button-action {
    font-size: 14px;
    color: #fff;
  }

  .fa-button-icon {
    width: 30px;
    height: 30px;
  
    margin-left: 30px;
  }

  .free-design-scroll-indicator-container {
    width: 100%;
    height: auto;
  
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  
    background-color: transparent;
  
    position: absolute;
    bottom: 10px;
  }

  .slide-container-free-design-text {
    font-size: var(--textAppearanceSmall);
  }
}

@media only screen and (min-width : 1201px) {
  .free-design-hero-text {
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: left;

    padding-left: 20px;
    padding-right: 20px;

    background-color: transparent;
  }

  .free-design-hero-text-h1 {
    font-size: 35px;
    color: white;
  }

  .free-design-hero-text-p {
    font-size: 14px;
    color: white;
  }

  .free-design-button-action {
    font-size: 14px;
    color: #fff;
  }

  .fa-button-icon {
    width: 30px;
    height: 30px;
  
    margin-left: 30px;
  }

  .free-design-scroll-indicator-container {
    width: 100%;
    height: auto;
  
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  
    background-color: transparent;
  
    position: absolute;
    bottom: 10px;
  }

  .slide-container-free-design-text {
    font-size: var(--textAppearanceSmall);
  }
}