.portofolio-rumah-01 {
    --colorPrimary: #2490E3;
    --colorPrimaryDark: #1E1E1E;
    --colorWhite: #FFFFFF;
    --colorGray: #dfdfdf;
    /** text size */
    --textAppearanceSmallest: 10px;
    --textAppearanceSmaller: 14px;
    --textAppearanceSmall: 16px;
    --textAppearanceMedium: 18px;
    --textAppearanceLarge: 22px;
    --globalTextSize: 18px;

    width: 100%;
    height: 100%;
    background-color: transparent;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.portofolio-rumah-01-container {
    position: relative;
    width: 100%;
    overflow: hidden;
}

.portofolio-rumah-01-container-img {
    /** image container fit to cover */
    object-fit: cover;
    /*
    transition: transform 1s, filter 2s ease-in-out;
    filter: blur(0);
    transform: scale(1);
    */
    transition: transform .3s ease-in-out;
    transform: scale(1.05);
}

.portofolio-rumah-01-container:hover .portofolio-rumah-01-container-img {
    /*
    filter: blur(1px);
    transform: scale(1.1);
    */
    transform: scale(1);
}

.portofolio-rumah-01-image {
    /** responsive image */
    width: 100%;
    height: auto;
    /** set display block */
    display: block;
    width: 100%;
    height: auto;

    transition: transform .5s ease;
}

.portofolio-rumah-01-overlay {
    position: absolute;
    bottom: 0;
    background: rgb(0, 0, 0);
    background: rgba(0, 0, 0, 0.5);
    /* Black see-through */
    color: #f1f1f1;
    width: 100%;
    height: 100%;
    transition: .5s ease;
    opacity: 0;
    color: white;
    font-size: 20px;
    padding: 20px;
    text-align: center;
    /** center position item */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.portofolio-rumah-01-overlay h1 {
    /** ukuran text judul header */
    font-size: 3.0rem;
    color: var(--colorWhite);
}

.portofolio-rumah-01-overlay p {
    /** ukuran text judul sub header */
    font-size: 22px;
    color: var(--colorWhite);
    /** set margin text */
    margin-top: 3px;
}

.portofolio-rumah-01-container:hover .portofolio-rumah-01-overlay {
    /** set cursor mouse */
    cursor: pointer;
    opacity: 1;
}

/**
  320px — 480px: Mobile devices
  481px — 768px: iPads, Tablets
  769px — 1024px: Small screens, laptops
  1025px — 1200px: Desktops, large screens
  1201px and more —  Extra large screens, TV
*/

@media only screen and (min-width : 320px) and (max-width : 480px) {
    .portofolio-rumah-01-overlay h1 {
        font-size: var(--textAppearanceSmaller);
        color: var(--colorWhite);
    }
    
    .portofolio-rumah-01-overlay p {
        font-size: var(--textAppearanceSmallest);
        color: var(--colorWhite);
        /** set margin text */
        margin-top: 2px;
    }    
}

@media only screen and (min-width : 481px) and (max-width : 768px) {

}

@media only screen and (min-width : 769px) and (max-width : 1024px) {

}

@media only screen and (min-width : 1025px) and (max-width : 1200px) {

}

@media only screen and (min-width : 1201px) {

}