.text-pengantar-container {
  width: 93%;
  height: auto;

  margin-bottom: 30px;
  padding: 20px;
  
  display: flex;
  flex-direction: column;
  justify-items: center;
  text-align: center;

  background-color: #EEEBE3;

  /** request boder radius corner*/
  border-radius: 10px;
}

.text-pengantar-header-a {
  color: #01415B;
  font-size: 34px;

  background-color: transparent;
}

.text-pengantar-a {
  padding-top: 3%;
  padding-bottom: 3%;
  color: rgb(36, 36, 36);
  font-size: 16px;
  text-align: left;

  background-color: transparent;
}

.text-pengantar-align-left {
  color: #01415B;
  font-size: 26px;
  text-align: left;

  background-color: transparent;
}
  
  /** responsive media screen */
  /**
    320px — 480px: Mobile devices
    481px — 768px: iPads, Tablets
    769px — 1024px: Small screens, laptops
    1025px — 1200px: Desktops, large screens
    1201px and more —  Extra large screens, TV
   */
  
  
  /*
  @media only screen and (min-width : 320px) and (max-width : 480px) {
          
  }
  
  @media only screen and (min-width : 481px) and (max-width : 768px) {
          
  }
  
  @media only screen and (min-width : 769px) and (max-width : 1024px) {
          
  }
  
  @media only screen and (min-width : 1025px) and (max-width : 1200px) {
          
  }
  
  @media only screen and (min-width : 1201px) {
          
  }
  */
  
  @media only screen and (min-width : 320px) and (max-width : 480px) {
    .text-pengantar-header-a {
      font-size: 28px;
    }
    
    .text-pengantar-a {
      font-size: 14px;
    }
    
    .text-pengantar-align-left {
      font-size: 22px;
    }
  }
  
  @media only screen and (min-width : 481px) and (max-width : 768px) {
    .text-pengantar-header-a {
      font-size: 34px;
    }
    
    .text-pengantar-a {
      font-size: 16px;
    }
    
    .text-pengantar-align-left {
      font-size: 26px;
    }
  }
  
  @media only screen and (min-width : 769px) and (max-width : 1024px) {
    .text-pengantar-header-a {
      font-size: 34px;
    }
    
    .text-pengantar-a {
      font-size: 16px;
    }
    
    .text-pengantar-align-left {
      font-size: 26px;
    }
  }
  
  @media only screen and (min-width : 1025px) and (max-width : 1200px) {
    .text-pengantar-header-a {
      font-size: 34px;
    }
    
    .text-pengantar-a {
      font-size: 16px;
    }
    
    .text-pengantar-align-left {
      font-size: 26px;
    }
  }
  
  @media only screen and (min-width : 1201px) {
    .text-pengantar-header-a {
      font-size: 34px;
    }
    
    .text-pengantar-a {
      font-size: 16px;
    }
    
    .text-pengantar-align-left {
      font-size: 26px;
    }
  }