.navbar-2 {
  /** color res */
  --colorTransparent: transparent;
  --colorPrimary: #2490E3;
  --colorPrimaryDark: #1E1E1E;
  --colorWhite: #FFFFFF;
  --colorGray: #dfdfdf;
  --bg-nav-item-hover: #2490E3;
  --bg-nav-on-hover: rgba(225, 225, 225, 0.3);

  /** dimen nav default */
  --navWidth: 100%;
  --navHeight: 70px;
  /** dimen navigation mobile screen */
  --navWidthMobileScreen: 100%;
  --navHeightMobileScreen: 45px;
  /** padding untuk navigation text */
  --paddingLeftText: 20px;
  --paddingRightText: 20px;
  --paddingTopText: 20px;
  --paddingBottomText: 20px;
  /** dimen logo */
  --logoWidth: auto;
  --logoHeight: 30px;
  /** space diantara logo dan navigation text */
  --spaceNavTextItemHeader: 100px;
  --spaceNavMoreActionHeader: 100px;
  /** padding button call us*/
  --paddingTopButton: 5px;
  --paddingBottomButton: 5px;
  --paddingLeftButton: 15px;
  --paddingRightButton: 15px;
  /** row item (button text on hover)*/
  --rowItemPaddingTop: 100%;
  --rowItemPaddingBottom: 100%;
  /** background navigation dropdown */
  --cornerRadius: 50px;
  /** dropdown menustyles */
  --textNavAppearanceExtraSmall: 9px;
  --textNavAppearanceSmall: 12px;
  --textNavAppearanceMedium: 14px;
  --textNavAppearanceLarge: 16px;
  --globalNavTextSize: 18px;
  /** dropdown content a */
  --dropdownContentPaddingTop: 2px;
  --dropdownContentPaddingBottom: 2px;
  --dropdownContentPaddingLeft: 15px;
  --dropdownContentPaddingRight: 15px;

  width: var(--navWidth);
  height: var(--navHeight);
  background-color: var(--colorTransparent);
  position: fixed;
  top: 0;
  z-index: 500;
}

.navbar-2 .navbar-2-container {
  padding-left: 50px;
  padding-right: 50px;
  background-color: transparent;
}

.navbar-2-horizontal-item {
  width: var(--navWidth);
  height: var(--navHeight);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  /** bg container nav component */
  background-color: transparent;
  overflow: hidden;
}

.navbar-2-base-header {
  /** set display grid */
  display: flex;
  grid-template-columns: auto 1fr;
  grid-template-rows: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  grid-gap: 0px;
  width: 100%;

  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: transparent;
}

.navbar-2-logo-header {
  width: auto;
  height: var(--navHeight);
  overflow: hidden;
  /** center logo */
  display: flex;
  justify-content: center;
  align-items: center;

  background-color: transparent;
}

.left-logo {
  width: var(--logoWidth);
  height: var(--logoHeight);
}

.navbar-2-link-header {
  display: flex;
  justify-content: center;
  align-items: center;
  /** set overflow hidden */
  overflow: hidden;
  /** set margin left from logo */
  margin-left: var(--spaceNavTextItemHeader);
  background-color: transparent;
}

.navbar-2-row-item {
  padding-top: var(--rowItemPaddingTop);
  padding-bottom: var(--rowItemPaddingBottom);
  /** bg text item */
  background-color: transparent;
  overflow: hidden;
}

.text-item {
  /** font-family: 'system-ui', Arial, Helvetica; */
  font-family:Arial, Helvetica, sans-serif;
  color: var(--colorWhite);
  font-size: var(--textNavAppearanceMedium);
  text-decoration: none;
  /** set padding untuk text*/
  padding-left: var(--paddingLeftText);
  padding-right: var(--paddingRightText);
  padding-top: var(--paddingTopText);
  padding-bottom: var(--paddingBottomText);

  background-color: transparent;
}

.text-item:hover {
  color: var(--colorPrimary);
  cursor: pointer;
}

.navbar-2-row-item-dropdown {
  padding-top: var(--rowItemPaddingTop);
  padding-bottom: var(--rowItemPaddingBottom);
  /** bg untuk dropdown header */
  background-color: transparent;
  overflow: hidden;
}

.text-item-dropdown {
  font-family: 'system-ui', Arial, Helvetica;
  color: var(--colorWhite);
  font-size: var(--textNavAppearanceMedium);
  /** set padding untuk text*/
  padding-left: var(--paddingLeftText);
  padding-right: var(--paddingRightText);

  background-color: transparent;
}

.navbar-2-row-item-dropdown:hover .text-item-dropdown {
  color: var(--colorPrimary);
  cursor: pointer;
}

.navbar-2-dropdown-content {
  visibility: hidden;
  width: 100%;
  height: auto;
  /** center layout */
  margin: auto;
  left: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  position: absolute;
  top: var(--navHeight);
  z-index: 500;
  overflow: hidden;
  /** background floating menu dropdown */
  background-color: transparent;
}

.navbar-2-dropdown-content a:hover {
  background-color: transparent;
  color: var(--colorPrimary);
}

/* Show the dropdown menu when the user moves the mouse over the dropdown button */
.navbar-2-row-item-dropdown:hover .navbar-2-dropdown-content {
  visibility: visible;
}

.navbar-2-text-link {
  width: auto;
  height: 60px;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: var(--colorWhite);
  /** set margin */
  margin-top: 1px;
  margin-bottom: 5px;

  padding-left: 20px;
  padding-right: 20px;

  /** set rounded corner untuk bg dropdown */
  border-radius: var(--cornerRadius);
  overflow: hidden;
  /** set box shadow bg dropdown */
  box-shadow: 0 1px 1px 1px rgba(0, 0, 0, 0.2);
}

/* Style the links inside the dropdown */
.navbar-2-text-link-text-item {
  height: auto;
  
  float: none;
  color: var(--colorPrimaryDark);

  padding-top: var(--dropdownContentPaddingTop);
  padding-bottom: var(--dropdownContentPaddingBottom);
  padding-left: var(--dropdownContentPaddingLeft);
  padding-right: var(--dropdownContentPaddingRight);

  margin-top: 5px;
  margin-bottom: 5px;
  margin-left: 5px;
  margin-right: 5px;

  text-decoration: none;
  text-align: center;
  display: block;

  font-family: 'system-ui', Arial, Helvetica;
  font-size: var(--textNavAppearanceMedium);
  text-align: left;
  background-color: transparent;
  /** set rounded corner selected bg */
  border-radius: 0%;
}

.navbar-2-more-action-header {
  background-color: transparent;
  /** set margin left from text item */
  margin-left: var(--spaceNavMoreActionHeader);
  overflow: hidden;
}

.navbar-2-header-scroll-bg {
  /** set background nav scroll */
  background-color: var(--colorWhite);
  transition: background-color 1s ease;
  /** set box shadow untuk navigation scroll */
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.2);
}

/** mengganti warna warna text navigation ketika scroll ke bawah */
.navbar-2-header-scroll-text .text-item,
.navbar-2-header-scroll-text .text-item-dropdown {
  color: var(--colorPrimaryDark);
  transition: background-color 1s ease;
}

.navbar-2-side-nav {
  display: none;
}

@font-face {
  font-family: system-ui;
  src: url('../../../components/assets/fonts/sf_pro_display_font/sf-pro-text-medium.ttf');
}

/** ------------------------------------ media screen ------------------------------------ */
/**
  320px — 480px: Mobile devices
  481px — 768px: iPads, Tablets
  769px — 1024px: Small screens, laptops
  1025px — 1200px: Desktops, large screens
  1201px and more —  Extra large screens, TV
 */

@media only screen and (min-width : 320px) and (max-width : 480px) {

  .navbar-2 {
    height: var(--navHeightMobileScreen);
  }

  .navbar-2-horizontal-item {
    height: var(--navHeightMobileScreen);
  }

  .text-item {
    font-size: var(--textNavAppearanceSmall);
    /** set padding untuk text*/
    padding-left: 10px;
    padding-right: 10px;
    padding-top: var(--paddingTopText);
    padding-bottom: var(--paddingBottomText);
    background-color: transparent;
  }

  .text-item:hover {
    color: var(--colorPrimary);
  }

  .navbar-2-link-header {
    /** visibility gone or none */
    display: none;
    visibility: hidden;
  }

  .text-item-dropdown {
    color: var(--colorWhite);
    font-size: var(--textNavAppearanceSmall);
    /** set padding untuk text*/
    padding-left: 10px;
    padding-right: 10px;
    background-color: transparent;
  }

  .left-logo {
    width: var(--logoWidth);
    height: 20px;
  }

  .navbar-2-side-nav {
    display: block;
  }
}

@media only screen and (min-width : 481px) and (max-width : 768px) {
  .navbar-2 {
    height: var(--navHeightMobileScreen);
  }

  .navbar-2-horizontal-item {
    height: var(--navHeightMobileScreen);
  }

  .text-item {
    font-size: var(--textNavAppearanceSmall);
    /** set padding untuk text*/
    padding-left: 10px;
    padding-right: 10px;
    padding-top: var(--paddingTopText);
    padding-bottom: var(--paddingBottomText);
    background-color: transparent;
  }

  .text-item:hover {
    color: var(--colorPrimary);
  }

  .navbar-2-link-header {
    /** visibility gone or none */
    display: none;
    visibility: hidden;
  }

  .text-item-dropdown {
    color: var(--colorWhite);
    font-size: var(--textNavAppearanceSmall);
    /** set padding untuk text*/
    padding-left: 10px;
    padding-right: 10px;
    background-color: transparent;
  }

  .left-logo {
    width: var(--logoWidth);
    height: 25px;
  }

  .navbar-2-side-nav {
    display: block;
  }
}

@media only screen and (min-width : 769px) and (max-width : 1024px) {
  .text-item {
    font-size: var(--textNavAppearanceSmall);
    /** set padding untuk text*/
    padding-left: 10px;
    padding-right: 10px;
    padding-top: var(--paddingTopText);
    padding-bottom: var(--paddingBottomText);
    background-color: transparent;
  }

  .text-item:hover {
    color: var(--colorPrimary);
  }

  .navbar-2-row-item-dropdown {
    padding-top: var(--rowItemPaddingTop);
    padding-bottom: var(--rowItemPaddingBottom);
    /** bg untuk dropdown header */
    background-color: transparent;
  }

  .navbar-2-link-header {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    /** set margin left from logo */
    margin-left: 30px;
  }

  .navbar-2-more-action-header {
    /** set margin left from text item */
    margin-left: 30px;
  }

  .text-item-dropdown {
    color: var(--colorWhite);
    font-size: var(--textNavAppearanceSmall);
    /** set padding untuk text*/
    padding-left: 10px;
    padding-right: 10px;
    background-color: transparent;
  }

  .navbar-2-text-link {
    /** set margin */
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .navbar-2-text-link-text-item {
    font-size: var(--textNavAppearanceSmall);
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: var(--dropdownContentPaddingLeft);
    padding-right: var(--dropdownContentPaddingRight);
    background-color: transparent;
  }

  .text-item-dropdown {
    color: var(--colorWhite);
    font-size: var(--textNavAppearanceSmall);
    /** set padding untuk text*/
    padding-left: 10px;
    padding-right: 10px;
    background-color: transparent;
  }

  .left-logo {
    width: var(--logoWidth);
    height: 26px;
  }

  .navbar-2-side-nav {
    display: none;
  }
}

@media only screen and (min-width : 1025px) and (max-width : 1200px) {
  .text-item {
    font-size: var(--textNavAppearanceSmall);
    /** set padding untuk text*/
    padding-left: 20px;
    padding-right: 20px;
    padding-top: var(--paddingTopText);
    padding-bottom: var(--paddingBottomText);
    background-color: transparent;
  }

  .text-item:hover {
    color: var(--colorPrimary);
  }

  .navbar-2-link-header {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    /** set margin left from logo */
    margin-left: 60px;
  }

  .navbar-2-more-action-header {
    /** set margin left from text item */
    margin-left: 60px;
  }

  .text-item-dropdown {
    color: var(--colorWhite);
    font-size: var(--textNavAppearanceSmall);
    /** set padding untuk text*/
    padding-left: 10px;
    padding-right: 10px;
    background-color: transparent;
  }

  .left-logo {
    width: var(--logoWidth);
    height: 29px;
  }

  .navbar-2-side-nav {
    display: none;
  }

}

@media only screen and (min-width : 1201px) {
  .text-item {
    color: var(--colorWhite);
    font-size: var(--textNavAppearanceMedium);
    /** set padding untuk text*/
    padding-left: var(--paddingLeftText);
    padding-right: var(--paddingRightText);
    padding-top: var(--paddingTopText);
    padding-bottom: var(--paddingBottomText);
    background-color: transparent;
  }

  .text-item:hover {
    color: var(--colorPrimary);
  }

  .navbar-2-link-header {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
  }

  .text-item-dropdown {
    color: var(--colorWhite);
    font-size: var(--textNavAppearanceSmall);
    /** set padding untuk text*/
    padding-left: 10px;
    padding-right: 10px;
    background-color: transparent;
  }

  .left-logo {
    width: var(--logoWidth);
    height: 33px;
  }

  .navbar-2-side-nav {
    display: none;
  }
}

/**
  320px — 480px: Mobile devices
  481px — 768px: iPads, Tablets
  769px — 1024px: Small screens, laptops
  1025px — 1200px: Desktops, large screens
  1201px and more —  Extra large screens, TV
 */


/*
@media only screen and (min-width : 320px) and (max-width : 480px) {
        
}

@media only screen and (min-width : 481px) and (max-width : 768px) {
        
}

@media only screen and (min-width : 769px) and (max-width : 1024px) {
        
}

@media only screen and (min-width : 1025px) and (max-width : 1200px) {
        
}

@media only screen and (min-width : 1201px) {
        
}
*/