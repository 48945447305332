.side-nav-dropbtn {
  /** color res */
  --colorTransparent: transparent;
  --colorPrimary: #2490E3;
  --colorPrimaryDark: #1E1E1E;
  --colorWhite: #FFFFFF;
  --colorGray: #dfdfdf;
  --bg-nav-item-hover: #2490E3;
  --bg-nav-on-hover: rgba(225, 225, 225, 0.3);

  /** dimen res */
  --nav-font-size: 13px;
  --nav-dropdown-font-size: 12px;

  width: 45px;
  height: 45px;

  background-color: transparent;
  padding: 15px;

  font-family: 'system-ui', Arial, Helvetica;
  font-size: 14px;
  border: none;
  cursor: pointer;

  display: none;
}

.side-nav-dropbtn:hover,
.side-nav-dropbtn:focus {
  background-color: transparent;
}

.side-nav-icon-menu {
  width: 15px;
  height: 15px;
}

.side-nav-icon-close-menu {
  width: auto;
  height: 18px;

  padding-left: 10px;
  padding-right: 10px;
  padding-top: 2px;
  padding-bottom: 2px;

  background-color: transparent;
  border-radius: 20px;
}

.side-nav-icon-menu,
.side-nav-icon-close-menu:hover {
  cursor: pointer;
}

.side-nav-dropdown-container {
  width: 100%;

  background-color: transparent;

  position: absolute;
  right: 0;
  top: 0;
  display: flex;
  flex-direction: row;
}

.side-nav-dropdown-left-container {
  width: 100%;

  display: flex;
  flex-direction: column;

  background-color: transparent;
}

.side-nav-dropdown-right-container {
  width: auto;

  position: fixed;
  top: 15px;
  right: 10px;
  z-index: 100;

  display: flex;
  flex-direction: column;

  background-color: transparent;
}

.side-nav-dropdown-content {
  width: 100%;
  height: 100vh;

  display: none;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 100;
  top: 0px;
  overflow: auto;
  
  backdrop-filter: blur(5px);
}

.side-nav-dropdown-content-menu-item {
  /** full match parent*/
  width: 100%;

  height: auto;
  display: block;
  z-index: 200;

  padding: 5px;
  background-color: #ffffff;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.2);
}

.side-nav-link-text-item {
  text-decoration: none;
  text-align: left;
}

.side-nav-dropdown-content a {
  font-family: 'system-ui', Arial, Helvetica;
  font-size: 14px;
  color: black;
  text-decoration: none;
  display: block;

  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 5px;
  padding-right: 5px;
}

.side-nav-dropdown-container a:hover {
  color: var(--colorPrimary);
  background-color: var(--colorGray);
  border-radius: 5px;
}

.side-nav-show {
  display: block;
}

@-webkit-keyframes slide {
  100% { left: 0; }
}

@keyframes slide {
  100% { left: 0; }
}

@font-face {
  font-family: system-ui;
  src: url('../../../../components/assets/fonts/sf_pro_display_font/sf-pro-text-medium.ttf');
}

/** responsive media screen */
/**
  320px — 480px: Mobile devices
  481px — 768px: iPads, Tablets
  769px — 1024px: Small screens, laptops
  1025px — 1200px: Desktops, large screens
  1201px and more —  Extra large screens, TV
 */


/*
@media only screen and (min-width : 320px) and (max-width : 480px) {
        
}

@media only screen and (min-width : 481px) and (max-width : 768px) {
        
}

@media only screen and (min-width : 769px) and (max-width : 1024px) {
        
}

@media only screen and (min-width : 1025px) and (max-width : 1200px) {
        
}

@media only screen and (min-width : 1201px) {
        
}
*/

@media only screen and (min-width : 320px) and (max-width : 480px) {
  .side-nav-dropbtn {
    display: block;
  }

  .side-nav-dropdown-content a {
    font-size: 14px;
    color: black;
  }
}

@media only screen and (min-width : 481px) and (max-width : 768px) {
  .side-nav-dropbtn {
    display: block;
  }

  .side-nav-dropdown-content a {
    font-size: 14px;
    color: black;
  }
}

@media only screen and (min-width : 769px) and (max-width : 1024px) {
  .side-nav-dropbtn {
    display: none;
  }

  .side-nav-dropdown-content a {
    font-size: 14px;
    color: black;
  }
}

@media only screen and (min-width : 1025px) and (max-width : 1200px) {
  .side-nav-dropbtn {
    display: none;
  }

  .side-nav-dropdown-content a {
    font-size: 14px;
    color: black;
  }
}

@media only screen and (min-width : 1201px) {
  .side-nav-dropbtn {
    display: none;
  }

  .side-nav-dropdown-content a {
    font-size: 14px;
    color: black;
  }
}