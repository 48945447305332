.service-section {
    width: 100%;
    height: 100%;
    background-color: white;
}

.service-main-container {
    width: 100%;
    height: 100%;

    background-color: transparent;
}

/** responsive media screen */
/**
  320px — 480px: Mobile devices
  481px — 768px: iPads, Tablets
  769px — 1024px: Small screens, laptops
  1025px — 1200px: Desktops, large screens
  1201px and more —  Extra large screens, TV
 */

/*
@media only screen and (min-width : 320px) and (max-width : 480px) {
        
}

@media only screen and (min-width : 481px) and (max-width : 768px) {
        
}

@media only screen and (min-width : 769px) and (max-width : 1024px) {
        
}

@media only screen and (min-width : 1025px) and (max-width : 1200px) {
        
}

@media only screen and (min-width : 1201px) {
        
}
*/

@media only screen and (min-width : 320px) and (max-width : 480px) {

}

@media only screen and (min-width : 481px) and (max-width : 768px) {
 
}

@media only screen and (min-width : 769px) and (max-width : 1024px) {
 
}

@media only screen and (min-width : 1025px) and (max-width : 1200px) {
   
}

@media only screen and (min-width : 1201px) {
    
}