.our-service-view-detail-section {
    width: 100%;
    height: 100vh;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    background-color: white;
}

.our-service-view-detail-container {
    width: 100%;
    height: auto;

    padding-left: 10%;
    padding-right: 10%;

    background-color: transparent;
}

.our-service-view-detail-container-grid {
    width: 100%;
    height: 100%;

    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 20px;
    background-color: transparent;
}

.our-service-view-detail-left-row {
    width: 100%;
    height: auto;

    overflow: auto;
    margin: auto;

    display: flex;
    justify-content: left;
    flex-direction: column;

    align-items: left;

    padding-left: 0px;
    padding-right: 100px;
    padding-top: 100px;
    padding-bottom: 100px;

    background-color: transparent;
}

.our-service-view-detail-right-row {
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    background-color: transparent;
}

.our-service-view-detail-image {
    width: 100%;
    height: 600px;
    object-fit: cover;
}

.our-service-view-detail-text-header {
    font-size: 24px;
    text-transform: uppercase;
    color: black;
}

.our-service-view-detail-text-item {
    font-size: 14px;
    margin-top: 40px;

    color: black;
}

/** responsive media screen */
/**
  320px — 480px: Mobile devices
  481px — 768px: iPads, Tablets
  769px — 1024px: Small screens, laptops
  1025px — 1200px: Desktops, large screens
  1201px and more —  Extra large screens, TV
 */


/*
@media only screen and (min-width : 320px) and (max-width : 480px) {
        
}

@media only screen and (min-width : 481px) and (max-width : 768px) {
        
}

@media only screen and (min-width : 769px) and (max-width : 1024px) {
        
}

@media only screen and (min-width : 1025px) and (max-width : 1200px) {
        
}

@media only screen and (min-width : 1201px) {
        
}
*/

@media only screen and (min-width : 320px) and (max-width : 480px) {
    .our-service-view-detail-section {
        height: auto;
    }

    .our-service-view-detail-text-header {
        font-size: 45px;
    }

    .our-service-view-detail-container {
        padding-left: 5%;
        padding-right: 5%;
    }

    .our-service-view-detail-container-grid {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        grid-gap: 10px;
    }

    .our-service-view-detail-image {
        width: 100%;
        height: auto;
        object-fit: cover;
    }

    .our-service-view-detail-left-row {
        display: flex;
        flex-direction: column;
        justify-content: center;

        align-items: left;

        padding-left: 10px;
        padding-right: 10px;
        padding-top: 50px;
        padding-bottom: 50px;

        background-color: transparent;
    }
}

@media only screen and (min-width : 481px) and (max-width : 768px) {
    .our-service-view-detail-section {
        height: 100%;
    }

    .our-service-view-detail-text-header {
        font-size: 65px;
    }
    
    .our-service-view-detail-container {
        padding-left: 5%;
        padding-right: 5%;
    }

    .our-service-view-detail-container-grid {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        grid-gap: 10px;
    }

    .our-service-view-detail-image {
        width: 100%;
        height: auto;
        object-fit: cover;
    }

    .our-service-view-detail-left-row {
        display: flex;
        flex-direction: column;
        justify-content: center;

        align-items: left;

        padding-left: 10px;
        padding-right: 10px;
        padding-top: 50px;
        padding-bottom: 50px;

        background-color: transparent;
    }
}

@media only screen and (min-width : 769px) and (max-width : 1024px) {
    .our-service-view-detail-section {
        height: 100%;
    }

    .our-service-view-detail-text-header {
        font-size: 65px;
    }

    .our-service-view-detail-container {
        padding-left: 5%;
        padding-right: 5%;
    }

    .our-service-view-detail-container-grid {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        grid-gap: 10px;
    }

    .our-service-view-detail-image {
        width: 100%;
        height: auto;
        object-fit: cover;
    }

    .our-service-view-detail-left-row {
        display: flex;
        flex-direction: column;
        justify-content: center;

        align-items: left;

        padding-left: 10px;
        padding-right: 10px;
        padding-top: 50px;
        padding-bottom: 50px;

        background-color: transparent;
    }
}

@media only screen and (min-width : 1025px) and (max-width : 1200px) {
    .our-service-view-detail-section {
        height: 100%;
    }

    .our-service-view-detail-text-header {
        font-size: 65px;
    }

    .our-service-view-detail-container {
        padding-left: 5%;
        padding-right: 5%;
    }

    .our-service-view-detail-container-grid {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 10px;
    }

    .our-service-view-detail-image {
        width: 100%;
        height: auto;
        object-fit: cover;
    }

    .our-service-view-detail-left-row {
        display: flex;
        flex-direction: column;
        justify-content: center;

        align-items: left;

        padding-left: 10px;
        padding-right: 10px;
        padding-top: 50px;
        padding-bottom: 50px;

        background-color: transparent;
    }
}

@media only screen and (min-width : 1201px) {
    .our-service-view-detail-section {
        height: 100%;
    }

    .our-service-view-detail-text-header {
        font-size: 65px;
    }
}