.gallery-house {
  --colorPrimary: #2490E3;
  --colorPrimaryDark: #1E1E1E;
  --colorWhite: #FFFFFF;
  --colorGray: #dfdfdf;
  /** dimen */
  --dimenFromNavHeader: 50px;
  /** border radius */
  --imageRoundedCorner: 5px;
  --overlayOnHoverRoundedCorner: 5px;

  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;

  padding-left: 10%;
  padding-right: 10%;

  /** animate text */
  animation: loadImg 1s ease;

  overflow: hidden;
}

.gallery-house-hero-image {
  width: 100%;
  height: 300px;

  background-color: white;

  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

.gallery-house-hero-text {
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
}

.gallery-house-h1 {

  font-size: 44px;
  color: var(--colorPrimaryDark);
  text-transform: uppercase;

  margin-top: 40px;
  margin-bottom: 40px;

  /** animate text */
  animation: loadH1 1s ease;

  /** request single line */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.row-house {
  display: grid;
  grid-template-columns: repeat(3, 1fr);

  padding-bottom: 20px;

  /** animate text */
  animation: loadH1 1s ease;
}

.column-house {
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.project-header {
  background-color: transparent;
  padding: 2px;
}

.project-container {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: transparent;

  overflow: hidden;
}

.image {
  width: 100%;
  height: auto;

  display: block;
  object-fit: cover;
}

.project-overlay {
  position: absolute;
  bottom: 0;
  background: rgb(0, 0, 0);
  background: rgba(0, 0, 0, 0.5);
  /* Black see-through */
  width: 100%;
  height: 100%;
  transition: .5s ease;
  opacity: 0;
  color: white;
  padding: 20px;
  text-align: center;
}

.content-title {
  font-size: 17px;
  position: absolute;
  bottom: 10%;
  left: 3%;
}

.content-title a {
  color: white;
  background-color: transparent;
  text-decoration: none;
}

.content-title a:hover {
  cursor: pointer;
  color: #2490E3;
}

.content-year {
  font-size: 11px;
  position: absolute;
  bottom: 3%;
  left: 3%;
  /* center fix
  transform: translate(-50%, -50%);
  */
}

/* When you mouse over the container, fade in the overlay title */
.project-container:hover .project-overlay {
  opacity: 1;
  cursor: pointer;
}

/* Keyframe animation */
@keyframes loadH1 {
  0% {
    opacity: 0;
    transform: translateY(3em);
  }

  30%,
  50% {
    opacity: 0;
    transform: translateY(3em);
  }

  100% {
    opacity: 1;
    transform: none;
  }
}

@keyframes loadImg {
  0% {
    opacity: 0;
    transform: translateY(100%);
  }

  100% {
    transform: none;
  }
}

/* Fading animation animation pages transition*/
.fade {
  -webkit-animation: fadein 1s;
  /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 1s;
  /* Firefox < 16 */
  -ms-animation: fadein 1s;
  /* Internet Explorer */
  -o-animation: fadein 1s;
  /* Opera < 12.1 */
  animation: fadein 1s;
}

@keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

/* Internet Explorer */
@-ms-keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

/** responsive media screen */
/**
  320px — 480px: Mobile devices
  481px — 768px: iPads, Tablets
  769px — 1024px: Small screens, laptops
  1025px — 1200px: Desktops, large screens
  1201px and more —  Extra large screens, TV
 */


/*
@media only screen and (min-width : 320px) and (max-width : 480px) {
        
}

@media only screen and (min-width : 481px) and (max-width : 768px) {
        
}

@media only screen and (min-width : 769px) and (max-width : 1024px) {
        
}

@media only screen and (min-width : 1025px) and (max-width : 1200px) {
        
}

@media only screen and (min-width : 1201px) {
        
}
*/

@media only screen and (min-width : 320px) and (max-width : 480px) {
  .row-house {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
  }

  .gallery-house {
    padding-left: 2%;
    padding-right: 2%;
  }

  .gallery-house-h1 {
    font-size: 24px;
  }
}

@media only screen and (min-width : 481px) and (max-width : 768px) {
  .row-house {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }

  .gallery-house {
    padding-left: 5%;
    padding-right: 5%;
  }

  .gallery-house-h1 {
    font-size: 34px;
  }
}

@media only screen and (min-width : 769px) and (max-width : 1024px) {
  .row-house {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }

  .gallery-house {
    padding-left: 5%;
    padding-right: 5%;
  }

  .gallery-house-h1 {
    font-size: 34px;
  }
}

@media only screen and (min-width : 1025px) and (max-width : 1200px) {
  .row-house {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }

  .gallery-house {
    padding-left: 5%;
    padding-right: 5%;
  }

  .gallery-house-h1 {
    font-size: 44px;
  }
}

@media only screen and (min-width : 1201px) {
  .row-house {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }

  .gallery-house {
    padding-left: 10%;
    padding-right: 10%;
  }

  .gallery-house-h1 {
    font-size: 44px;
  }
}