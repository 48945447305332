.floating-back-button {
    width: 40px;
    height: 40px;

    position: fixed;
    bottom: 10px;
    right: 10px;
    border-radius: 0px;
    background-color: #096bb5;
    color: white;
    border: none;

    overflow: hidden;
    
    padding: 10px;
    cursor: pointer;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
    transition: background-color 0.3s, transform 0.3s;
    z-index: 500;

    border-radius: 30%;
  }
  
  .floating-back-button:hover {
    background-color: #555;
    transform: scale(1.1);
  }
  
  .back-icon {
    font-size: 18px;
  }

  /** responsive media screen */
/**
  320px — 480px: Mobile devices
  481px — 768px: iPads, Tablets
  769px — 1024px: Small screens, laptops
  1025px — 1200px: Desktops, large screens
  1201px and more —  Extra large screens, TV
 */


/*
@media only screen and (min-width : 320px) and (max-width : 480px) {
        
}

@media only screen and (min-width : 481px) and (max-width : 768px) {
        
}

@media only screen and (min-width : 769px) and (max-width : 1024px) {
        
}

@media only screen and (min-width : 1025px) and (max-width : 1200px) {
        
}

@media only screen and (min-width : 1201px) {
        
}
*/

/** Phone */
@media only screen and (min-width : 320px) and (max-width : 480px) {
  .floating-back-button {
      right: 85%;
      bottom: 3%;
  }
}

/** Tablet */
@media only screen and (min-width : 481px) and (max-width : 768px) {
  .floating-back-button {
      right: 90%;
      bottom: 3%;
  }
}

/** Tablet Horizontal */
@media only screen and (min-width : 769px) and (max-width : 1024px) {
  .floating-back-button {
      right: 92%;
      bottom: 3%;
  }
}

/** Laptop */
@media only screen and (min-width : 1025px) and (max-width : 1200px) {
  .floating-back-button {
      right: 95%;
      bottom: 4%;
  }
}

/** PC Dekstop */
@media only screen and (min-width : 1201px) {
  .floating-back-button {
      right: 95%;
      bottom: 4%;
  }
}