.floating-whatsapp {
    /** color res */
    --colorFloatTransparent: transparent;
    --colorFloatPrimary: #2490E3;
    --colorFloatPrimaryDark: #1E1E1E;
    --colorFloatWhite: #FFFFFF;
    --colorFloatGray: #dfdfdf;
    --colorFloatBigHeader: #2DB642;
    --bgFloatTitleInfo: #F6F7F9;

    /** border radius */
    --borderRadiusBg: 5px;

    /** set position layout */
    position: fixed;
    justify-content: right;
    align-items: center;
    bottom: 0;
    right: 0;
    /** set default layout height floating whatsapp */
    width: 350px;
    height: auto;
    margin: auto;
    /** set order layout */
    z-index: 700;
    /** set margin bottom floating icon */
    margin-bottom: 70px;
    background-color: var(--colorFloatWhite);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    /** set margin right untuk space windows scroll indicator*/
    margin-right: 10px;
}

.floating-whatsapp .floating-whatsapp-container {
    background-color: var(--colorFloatTransparent);
}

.floating-whatsapp .floating-whatsapp-container .floating-whatsapp-content {
    /** default set all child hidden */
    display: none;
    background-color: var(--colorFloatTransparent);
    /** transisi animasi untuk show hidden content */
}

.close-panel {
    display: flex;
    justify-content: center;
    align-items: center;
    width: auto;
    height: 18px;
    background-color: var(--colorFloatBigHeader);
    /** set radius circle */
    border-radius: 50px;
    position: absolute;
    /** set position */
    top: 10px;
    right: 10px;
    justify-content: center;
    align-items: center;
    /** rev padding inner*/
    padding: 14px;

    /** text color */
    color: var(--colorFloatWhite);
}

.close-panel:hover {
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.2);
    color: var(--colorFloatWhite);
}

.close-panel a {
    font-size: 14px;
}

.chat-container {
    width: 100%;
    height: auto;
}

.menu-container {
    background-color: var(--colorFloatTransparent);
}

.connect-with-us {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-color: transparent;
    /** set font size */
    font-size: 13px;
    /** set padding text */
    padding: 5px;
}

.scrollview-y {
    /** set overflow scroll down */
    overflow-y: scroll;
}

.chat-activity {
    background-color: var(--colorFloatWhite);
    /** set padding */
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 15px;
    padding-bottom: 15px;
}

.horizontal-layout-menu {
    display: flex;
    align-items: center;
    justify-content: space-around;
    background-color: var(--colorFloatTransparent);
    /** set padding untuk menu container */
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 10px;
    padding-bottom: 10px;
}

.box-menu-item {
    /** background menu item */
    background-color: var(--colorFloatTransparent);
}

/** group hover untuk text menu item */
.box-menu-item:hover,
.component-i:hover {
    color: var(--colorFloatPrimary);
}

.component-i {
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: 2fr;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    grid-gap: 0px;
    width: 100%;
    background-color: transparent;
}

.component-i p a {
    display: flex;
    /** center content component i */
    justify-content: center;
    align-items: center;
    text-align: center;
    background-color: transparent;
    /** styles font more menu */
    font-size: 12px;
}

.icon-menu {
    background-color: transparent;
    /** set padding untuk icon more info */
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 5px;
    padding-bottom: 5px;
    /** set margin top */
    margin-top: 5px;
    /** background menu item */
    background-color: var(--colorFloatWhite);
    border-radius: 5px;
    border: 1px solid transparent;
}

/** group all untuk on hover icon menu */
.icon-menu:hover {
    cursor: pointer;
    background-color: rgba(36, 144, 227, 0.7);
}

.floating-icon-container {
    width: 100%;
    height: auto;
    background-color: transparent;
}

.whatsapp-icon {
    cursor: pointer;
    position: fixed;
    right: 0;
    bottom: 0;
    justify-content: right;
    align-items: right;
    background-color: transparent;
    /** set margin right untuk space windows scroll indicator*/
    margin-right: 8px;
    /** set margin bottom untuk space dari bawah */
    margin-bottom: 8px;

    /** animate bounce */
    animation: bounce2 2s ease infinite;
}

.whatsapp-icon-img {
    width: 45px;
    height: 45px;
}

@keyframes bounce2 {

    0%,
    20%,
    50%,
    80%,
    100% {
        transform: translateY(0);
    }

    40% {
        transform: translateY(-6px);
    }

    60% {
        transform: translateY(-2px);
    }
}

/**  animation: ripple-loader 1s ease-out infinite; */
@keyframes ripple-loader {
    0% {
        top: 32px;
        left: 32px;
        width: 0;
        height: 0;
        opacity: 1;
    }

    100% {
        top: 0;
        left: 0;
        width: 64px;
        height: 64px;
        opacity: 0;
    }
}

/** responsive media screen */
/**
  320px — 480px: Mobile devices
  481px — 768px: iPads, Tablets
  769px — 1024px: Small screens, laptops
  1025px — 1200px: Desktops, large screens
  1201px and more —  Extra large screens, TV
 */


/*
@media only screen and (min-width : 320px) and (max-width : 480px) {
        
}

@media only screen and (min-width : 481px) and (max-width : 768px) {
        
}

@media only screen and (min-width : 769px) and (max-width : 1024px) {
        
}

@media only screen and (min-width : 1025px) and (max-width : 1200px) {
        
}

@media only screen and (min-width : 1201px) {
        
}
*/

@media only screen and (min-width : 320px) and (max-width : 480px) {
    .floating-whatsapp {
        width: 100%;
        height: auto;
        margin: auto;
        z-index: 700;
    }

    .whatsapp-icon {
        display: block;
        bottom: 1.4%;
    }

    .whatsapp-icon-img {
        width: 45px;
        height: 45px;
    }
}

@media only screen and (min-width : 481px) and (max-width : 768px) {
    .floating-whatsapp {
        position: fixed;
        bottom: 50px;
        left: 0;
        
        width: 50%;
        height: auto;
        margin: auto;

        z-index: 700;
    }

    .whatsapp-icon {
        display: block;
        bottom: 1.5%;
    }
}

@media only screen and (min-width : 769px) and (max-width : 1024px) {
    .floating-whatsapp {
        position: fixed;
        bottom: 50px;
        left: 0;
        
        width: 50%;
        height: auto;
        margin: auto;

        z-index: 700;
    }

    .whatsapp-icon {
        display: block;
        bottom: 1.5%;
    }
}

@media only screen and (min-width : 1025px) and (max-width : 1200px) {
    .floating-whatsapp {
        position: fixed;
        bottom: 50px;
        left: 0;
        
        width: 50%;
        height: auto;
        margin: auto;

        z-index: 700;
    }

    .whatsapp-icon {
        display: block;
        bottom: 1.8%;
    }
}

@media only screen and (min-width : 1201px) {
    .floating-whatsapp {
        width: 350px;
        height: auto;
        margin: auto;
        z-index: 700;

        margin-bottom: 70px;
        margin-right: 10px;
    }

    .whatsapp-icon {
        display: block;
        bottom: 1.8%;
    }
}