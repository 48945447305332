.blog-section {
    /** color res */
    --colorTransparent: transparent;
    --colorPrimary: #2490E3;
    --colorPrimaryDark: #1E1E1E;
    --colorWhite: #FFFFFF;
    --colorGray: #dfdfdf;

    --marginTopFromBlogNavbar: 40px;

    width: 100%;
    height: 100%;
    
    scroll-behavior: smooth;
}

.blog-container {
    width: 100%;
    height: 100%;

    background-color: transparent;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.blog-container-nav {
    width: 100%;
}

.blog-hero {
    width: 100%;
    height: 100vh;

    position: relative;
    background-color: transparent;
}

.blog-section-hero-image {
    width: 100%;
    height: 100px;
    /*
    background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("../../../assets/images/project/rumah/GW_HOUSE/grand-wisata-02.jpg");
    */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
}

.blog-section-hero-text {
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.blog-section-hero-text-header {
    font-size: 35px;
    color: var(--colorPrimaryDark);
    text-transform: uppercase;
}

.blog-hero-container {
    width: auto;
    height: 100%;
    /* Use "linear-gradient" to add a darken background effect to the image (photographer.jpg). This will make the text easier to read */
    /**background-image: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url("../../../assets/images/project/rumah/grand-wisata-house/grand-wisata-02.jpg");*/
    /* Position and center the image to scale nicely on all screens */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
}

.main-video-bg {
    width: 100%;
    height: 100%;
}

.m-text-link-item {
    text-decoration: none;
}

.m-text-link-item a {
    font-size: 13px;
    color: white;

    padding-left: 20px;
    padding-right: 20px;
}

.m-text-link-item a:hover {
    cursor: pointer;
}

.video-bg-frame {
    object-fit: cover;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
}

.video-bg-content {
    position: absolute;
    width: 100%;
    height: 100%;

    padding-left: 10%;
    padding-right: 10%;

    padding-top: 20px;
    padding-bottom: 20px;

    top: 0;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: 1fr auto;

    justify-content: center;
    align-items: left;

    background-color: transparent;

    overflow-y: auto;
}

.video-bg-intro-header {
    width: 100%;
    margin-top: 50px;
    background-color: transparent;
    text-align: center;
}

.video-bg-content h1 {
    font-size: 45px;
    color: white;
}

.video-bg-horizontal-container {
    width: 100%;

    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-gap: 20px;

    padding-bottom: 5%;

    font-size: 13px;
    color: white;
}

.video-bg-horizontal-row {
    width: 100%;
    height: auto;

    padding: 20px;

    background-color: rgba(0, 0, 0, 0);
    border-radius: 5px;

    overflow: hidden;

    transform: translateY(0);
    opacity: 1;
    transition: 0.3s;
}

.video-bg-horizontal-row:hover {
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.2);
}

.video-bg-big-title {
    font-size: 20px;
    color: white;

    margin-bottom: 20px;
}

.video-bg-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
}

.blog-reveal-card {
    position: relative;
    transform: translateY(150px);
    opacity: 0;
    transition: 1s all ease;
}

.blog-reveal-card.blog-active-card {
    transform: translateY(0);
    opacity: 1;
}

.blog-reveal-card.blog-active-card:hover {
    cursor: pointer;
    position: relative;
    justify-content: center;
    align-items: center;
    z-index: 100;
    /*
    transform: scale(1);
    */
}

.m-image-social-bar:hover {
    cursor: pointer;
}

/** responsive media screen */
/**
  320px — 480px: Mobile devices
  481px — 768px: iPads, Tablets
  769px — 1024px: Small screens, laptops
  1025px — 1200px: Desktops, large screens
  1201px and more —  Extra large screens, TV
 */


/*
@media only screen and (min-width : 320px) and (max-width : 480px) {
        
}

@media only screen and (min-width : 481px) and (max-width : 768px) {
        
}

@media only screen and (min-width : 769px) and (max-width : 1024px) {
        
}

@media only screen and (min-width : 1025px) and (max-width : 1200px) {
        
}

@media only screen and (min-width : 1201px) {
        
}
*/

@media only screen and (min-width : 320px) and (max-width : 480px) {
    .video-bg-intro-header {
        padding: 5px;
    }

    .video-bg-content h1 {
        margin-top: 20px;
        font-size: 22px;
        color: white;
    }

    .video-bg-horizontal-container {
        width: 100%;
        height: 100%;
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        grid-gap: 0px;
        font-size: 10px;
    }

    .video-bg-horizontal-row {
        width: 100%;
        height: auto;

        padding: 5px;

        background-color: rgba(0, 0, 0, 0);
        border-radius: 5px;

        overflow: hidden;
    }

    .video-bg-big-title {
        font-size: 15px;
        color: white;
    }

    .blog-section-hero-text-header {
        font-size: 18px;
    }
}

@media only screen and (min-width : 481px) and (max-width : 768px) {
    .video-bg-intro-header {
        padding: 5px;
    }

    .video-bg-content h1 {
        margin-top: 20px;
        font-size: 30px;
        color: white;
    }

    .video-bg-horizontal-container {
        width: 100%;
        height: 100%;
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        grid-gap: 0px;
        font-size: 14px;
    }

    .video-bg-horizontal-row {
        width: 100%;
        height: auto;

        padding: 5px;

        background-color: rgba(0, 0, 0, 0);
        border-radius: 5px;

        overflow: hidden;
    }

    .video-bg-big-title {
        font-size: 21px;
        color: white;
    }

    .m-text-link-item {
        text-decoration: none;
    }

    .m-text-link-item a {
        color: white;
        font-size: 14px;
        padding-left: 20px;
        padding-right: 20px;
    }

    .blog-section-hero-text-header {
        font-size: 18px;
    }
}

@media only screen and (min-width : 769px) and (max-width : 1024px) {
    .video-bg-intro-header {
        padding: 5px;
    }

    .video-bg-content h1 {
        margin-top: 20px;
        font-size: 30px;
        color: white;
    }

    .video-bg-horizontal-container {
        width: 100%;
        height: 100%;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 0px;
        font-size: 14px;
    }

    .video-bg-horizontal-row {
        width: 100%;
        height: auto;

        padding: 5px;

        background-color: rgba(0, 0, 0, 0);
        border-radius: 5px;

        overflow: hidden;
    }

    .video-bg-big-title {
        font-size: 21px;
        color: white;
    }

    .m-text-link-item {
        text-decoration: none;
    }

    .m-text-link-item a {
        color: white;
        font-size: 14px;
        padding-left: 20px;
        padding-right: 20px;
    }

    .blog-section-hero-text-header {
        font-size: 18px;
    }
}

@media only screen and (min-width : 1025px) and (max-width : 1200px) {
    .video-bg-intro-header {
        padding: 5px;
    }

    .video-bg-content h1 {
        margin-top: 20px;
        font-size: 30px;
        color: white;
    }

    .video-bg-horizontal-container {
        width: 100%;
        height: 100%;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 0px;
        font-size: 14px;
    }

    .video-bg-horizontal-row {
        width: 100%;
        height: auto;

        padding: 5px;

        background-color: rgba(0, 0, 0, 0);
        border-radius: 5px;

        overflow: hidden;
    }

    .video-bg-big-title {
        font-size: 21px;
        color: white;
    }

    .m-text-link-item {
        text-decoration: none;
    }

    .m-text-link-item a {
        color: white;
        font-size: 14px;
        padding-left: 20px;
        padding-right: 20px;
    }

    .blog-section-hero-text-header {
        font-size: 18px;
    }
}

@media only screen and (min-width : 1201px) {
    .video-bg-intro-header {
        padding: 5px;
    }

    .video-bg-content h1 {
        margin-top: 20px;
        font-size: 50px;
        color: white;
    }

    .video-bg-horizontal-container {
        width: 100%;
        height: 100%;
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        grid-gap: 20px;
        font-size: 12px;
    }

    .video-bg-horizontal-row {
        width: 100%;
        height: auto;

        padding: 20px;

        background-color: rgba(0, 0, 0, 0);
        border-radius: 5px;

        overflow: hidden;
    }

    .video-bg-big-title {
        font-size: 15px;
        color: white;
    }

    .m-text-link-item {
        text-decoration: none;
    }

    .m-text-link-item a {
        padding-left: 20px;
        padding-right: 20px;
    }

    .blog-section-hero-text-header {
        font-size: 20px;
    }
}