.tabs-container {
  /** dimen navbar default */
  --navWidth: 100%;
  --navHeight: 70px;
  /** dimen navigation mobile screen */
  --navWidthMobileScreen: 100%;
  --navHeightMobileScreen: 45px;

  /** text menustyles */
  --textNavAppearanceExtraSmall: 9px;
  --textNavAppearanceSmall: 12px;
  --textNavAppearanceMedium: 14px;
  --textNavAppearanceLarge: 16px;
  --globalNavTextSize: 18px;

  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: auto;
  background-color: white;
}

.bloc-tabs {
  width: 100%;
  height: var(--navHeight);
  /** position fixed on top */
  position: fixed;
  top: 70;
  z-index: 500;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  /** bg quick navigation */
  background-color: white;

  /** set padding center text */
  padding-left: 20%;
  padding-right: 20%;
}

.tabs {
  width: 100%;
  height: 100%;
  
  padding: 15px;
  text-align: center;
  background-color: transparent;
  cursor: pointer;
  border-bottom: 1px solid transparent;
  box-sizing: content-box;
  position: relative;
  outline: none;

  font-size: var(--textNavAppearanceMedium);
}

.tabs:not(:last-child) {
  border-right: 1px solid transparent;
}

.active-tabs {
  background-color: transparent;
  border-bottom: 1px solid transparent;
  color: #2490E3;
}

button {
  border: none;
}

.content-tabs {
  flex-grow: 1;
}

.content {
  background: white;
  padding: 20px;
  width: 100%;
  height: 100%;
  display: none;
}

.content h2 {
  padding: 0px 0 5px 0px;
}

.content hr {
  width: 100px;
  height: 2px;
  background: #222;
  margin-bottom: 5px;
}

.content p {
  width: 100%;
  height: 100%;
}

.active-content {
  display: block;
}

@media only screen and (min-width : 320px) and (max-width : 480px) {
  .bloc-tabs {
    top: var(--navHeightMobileScreen);
    padding-left: 45%;
    padding-right: 45%;
  }
}

@media only screen and (min-width : 481px) and (max-width : 768px) {
  .bloc-tabs {
    top: var(--navHeightMobileScreen);
  } 
}

@media only screen and (min-width : 769px) and (max-width : 1024px) {
  .bloc-tabs {
    top: var(--navHeight);
  } 
}

@media only screen and (min-width : 1025px) and (max-width : 1200px) {
  .bloc-tabs {
    top: var(--navHeight);
  } 
}

@media only screen and (min-width : 1201px) {
  .bloc-tabs {
    top: var(--navHeight);
  } 
}

/**
  320px — 480px: Mobile devices
  481px — 768px: iPads, Tablets
  769px — 1024px: Small screens, laptops
  1025px — 1200px: Desktops, large screens
  1201px and more —  Extra large screens, TV
 */


/*
@media only screen and (min-width : 320px) and (max-width : 480px) {
        
}

@media only screen and (min-width : 481px) and (max-width : 768px) {
        
}

@media only screen and (min-width : 769px) and (max-width : 1024px) {
        
}

@media only screen and (min-width : 1025px) and (max-width : 1200px) {
        
}

@media only screen and (min-width : 1201px) {
        
}
*/