.footer {
	--rowWidth: 100%;
	/** color primary footer */
	--colorPrimaryBlueFooter: rgb(226, 226, 226);
	--colorPrimaryFooter: #1B2830;
	/** --colorPrimaryFooter: #343A40; */
	--colorPrimaryDarkFooter: #0D0D0D;
	/** --colorPrimaryDarkFooter: #0D0D0D; */
	--colorWhite: #FFFFFF;
	--colorTitleHeader: #ffffff;

	width: 100%;
	height: auto;

	display: flex;
	flex-direction: column;

	background-color: var(--colorPrimaryFooter);

	overflow: hidden;
}

.footer-container {
	margin-left: 100px;
	margin-right: 100px;
	margin-top: 20px;
	margin-bottom: 20px;
}

.footer-top-container {
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	grid-gap: 40px;
}

.footer-bottom-container {
	display: grid;
	grid-template-columns: 1fr auto;

	margin-top: 20px;

	background-color: transparent;
}

.footer-list-with-image {
	display: flex;
	flex-direction: row;
	justify-content: left;
	align-items: center;

	margin-top: 10px;
	margin-bottom: 10px;

	overflow: hidden;
}

.footer-list-with-image:hover {
	cursor: pointer;
}

.footer-row {
	background-color: transparent;
}

.footer-text {
	font-family: Arial, Helvetica, sans-serif;
	font-size: 14px;
	color: white;
	line-height: 1.2;

	background-color: transparent;
}

.title-header {
	font-family: Arial, Helvetica, sans-serif;
	font-size: 14px;
	color: var(--colorTitleHeader);
	opacity: 0.3;

	padding-top: 10px;
	padding-bottom: 10px;
}

.footer-text-info {
	text-decoration: none;
	font-family: Arial, Helvetica, sans-serif;
	font-size: 14px;
	color: var(--colorPrimaryBlueFooter);
	line-height: 1.2;

	margin-left: 5px;
}

.footer-text-link {
	text-decoration: none;
	color: var(--colorPrimaryBlueFooter);
}

.footer-text-link:hover {
	color: var(--colorPrimaryBlueFooter);
}

.f-bottom-left {
	width: 100%;
	height: auto;

	display: flex;
	flex-direction: row;
	justify-content: left;
	align-items: center;
}

.div-bootom-right {
	width: 100%;
	height: auto;

	display: flex;
	flex-direction: row;
	justify-content: left;
	align-items: center;
}

.footer-social-links {
	display: flex;
	flex-direction: row;

	margin-top: 10px;
}

.footer-fab-icon {
	width: auto;
	height: 25px;

	background-color: #FFFFFF;
	padding: 4px;

	margin-left: 4px;
	margin-right: 4px;

	border-radius: 50px;
}

.footer-fab-icon:hover {
	cursor: pointer;
	background-color: var(--colorPrimaryBlueFooter);
}

.footer-fab-list-image {
	width: auto;
	height: 25px;

	background-color: #FFFFFF;
	padding: 5px;

	border-radius: 50px;
}

@font-face {
	font-family: system-ui;
	src: url('../../components/assets/fonts/sf_pro_display_font/sf-pro-text-medium.ttf');
}

/** responsive media screen */
/**
  320px — 480px: Mobile devices
  481px — 768px: iPads, Tablets
  769px — 1024px: Small screens, laptops
  1025px — 1200px: Desktops, large screens
  1201px and more —  Extra large screens, TV
 */


/*
@media only screen and (min-width : 320px) and (max-width : 480px) {
        
}

@media only screen and (min-width : 481px) and (max-width : 768px) {
        
}

@media only screen and (min-width : 769px) and (max-width : 1024px) {
        
}

@media only screen and (min-width : 1025px) and (max-width : 1200px) {
        
}

@media only screen and (min-width : 1201px) {
   
}     
*/

@media only screen and (min-width : 320px) and (max-width : 480px) {
	.footer {
		width: 100%;
		height: 100%;
	}

	.footer-container {
		margin-left: 20px;
		margin-right: 20px;
		margin-top: 20px;
		margin-bottom: 20px;
	}

	.footer-top-container {
		display: grid;
		grid-template-columns: repeat(1, 1fr);
		grid-gap: 10px;
	}

	.footer-text {
		font-size: 12px;
		color: white;
		line-height: 1.2;

		background-color: transparent;
	}

	.title-header {
		font-size: 17px;
		color: white;
		padding-top: 8px;
		padding-bottom: 8px;
	}

	.footer-list-with-image {
		margin-top: 10px;
		margin-bottom: 10px;
	}

	.footer-fab-list-image {
		width: auto;
		height: 25px;
		background-color: #FFFFFF;
		padding: 5px;
	}

	.footer-text-info {
		font-size: 13px;
		line-height: 1.2;
		margin: 5px;
	}

	.footer-bottom-container {
		display: grid;
		grid-template-columns: repeat(1, 1fr);

		text-align: center;
	}

	.f-bottom-left {
		width: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}
}

@media only screen and (min-width : 481px) and (max-width : 768px) {
	.footer {
		width: 100%;
		height: 100%;
	}

	.footer-container {
		margin-left: 20px;
		margin-right: 20px;
		margin-top: 20px;
		margin-bottom: 20px;
	}

	.footer-top-container {
		display: grid;
		grid-template-columns: repeat(1, 1fr);
		grid-gap: 10px;
	}

	.footer-text {
		font-size: 13px;
		color: white;
		line-height: 1.2;

		background-color: transparent;
	}

	.title-header {
		font-size: 18px;
		color: white;
		padding-top: 8px;
		padding-bottom: 8px;
	}

	.footer-list-with-image {
		margin-top: 10px;
		margin-bottom: 10px;
	}

	.footer-fab-list-image {
		width: auto;
		height: 25px;
		background-color: #FFFFFF;
		padding: 5px;
	}

	.footer-text-info {
		font-size: 14px;
		line-height: 1.2;
		margin: 5px;
	}

	.footer-bottom-container {
		display: grid;
		grid-template-columns: repeat(2, 1fr);

		text-align: left;
	}

	.f-bottom-left {
		width: 100%;
	}
}

@media only screen and (min-width : 769px) and (max-width : 1024px) {
	.footer {
		width: 100%;
		height: 100%;
	}

	.footer-container {
		margin-left: 20px;
		margin-right: 20px;
		margin-top: 20px;
		margin-bottom: 20px;
	}

	.footer-top-container {
		display: grid;
		grid-template-columns: repeat(4, 1fr);
		grid-gap: 20px;
	}

	.footer-text {
		font-size: 13px;
		color: white;
		line-height: 1.2;

		background-color: transparent;
	}

	.title-header {
		font-size: 16px;
		color: white;
		padding-top: 8px;
		padding-bottom: 8px;
	}

	.footer-list-with-image {
		margin-top: 10px;
		margin-bottom: 10px;
	}

	.footer-fab-list-image {
		width: auto;
		height: 25px;
		background-color: #FFFFFF;
		padding: 5px;
	}

	.footer-text-info {
		font-size: 14px;
		line-height: 1.2;
		margin: 5px;
	}

	.footer-bottom-container {
		display: grid;
		grid-template-columns: repeat(2, 1fr);

		text-align: left;
	}

	.f-bottom-left {
		width: 100%;
	}
}

@media only screen and (min-width : 1025px) and (max-width : 1200px) {
	.footer {
		width: 100%;
		height: auto;

		display: flex;
		flex-direction: column;

		background-color: var(--colorPrimaryFooter);

		overflow: hidden;
	}

	.footer-container {
		margin-left: 100px;
		margin-right: 100px;
		margin-top: 20px;
		margin-bottom: 20px;
	}

	.footer-top-container {
		display: grid;
		grid-template-columns: repeat(4, 1fr);
		grid-gap: 40px;
	}

	.footer-bottom-container {
		display: grid;
		grid-template-columns: 1fr auto;

		margin-top: 20px;
	}

	.f-bottom-left {
		width: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}

	.footer-list-with-image {
		display: flex;
		flex-direction: row;
		justify-content: left;
		align-items: center;

		margin-top: 10px;
		margin-bottom: 10px;

		overflow: hidden;
	}

	.footer-row {
		background-color: transparent;
	}

	.footer-text {
		font-size: 13px;
		color: white;
		line-height: 1.2;

		background-color: transparent;
	}

	.title-header {
		font-size: 18px;
		color: white;

		padding-top: 10px;
		padding-bottom: 10px;
	}

	.footer-text-info {
		font-size: 13px;
		line-height: 1.2;

		margin: 5px;
	}

	.footer-text-link {
		text-decoration: none;
		color: var(--colorPrimaryBlueFooter);
	}

	.footer-text-link:hover {
		color: var(--colorPrimaryBlueFooter);
	}

	.f-bottom-left {
		width: 100%;
		height: auto;

		display: flex;
		flex-direction: row;
		justify-content: left;
		align-items: center;
	}

	.div-bootom-right {
		width: 100%;
		height: auto;

		display: flex;
		flex-direction: row;
		justify-content: left;
		align-items: center;
	}

	.footer-social-links {
		display: flex;
		flex-direction: row;

		margin-top: 10px;
	}

	.footer-fab-icon {
		width: auto;
		height: 25px;

		background-color: #FFFFFF;
		padding: 4px;

		margin-left: 4px;
		margin-right: 4px;

		border-radius: 50px;
	}

	.footer-fab-icon:hover {
		cursor: pointer;
		background-color: var(--colorPrimaryBlueFooter);
	}

	.footer-fab-list-image {
		width: auto;
		height: 25px;

		background-color: #FFFFFF;
		padding: 5px;

		border-radius: 50px;
	}
}

@media only screen and (min-width : 1201px) {
	.footer {
		width: 100%;
		height: auto;

		display: flex;
		flex-direction: column;

		background-color: var(--colorPrimaryFooter);

		overflow: hidden;
	}

	.footer-container {
		margin-left: 100px;
		margin-right: 100px;
		margin-top: 100px;
		margin-bottom: 100px;
	}

	.footer-top-container {
		display: grid;
		grid-template-columns: repeat(4, 1fr);
		grid-gap: 40px;
	}

	.footer-bottom-container {
		display: grid;
		grid-template-columns: 1fr auto;

		margin-top: 20px;
	}

	.f-bottom-left {
		width: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}

	.footer-list-with-image {
		display: flex;
		flex-direction: row;
		justify-content: left;
		align-items: center;

		margin-top: 10px;
		margin-bottom: 10px;

		overflow: hidden;
	}

	.footer-row {
		background-color: transparent;
	}

	.footer-text {
		font-size: 13px;
		color: white;
		line-height: 1.2;

		background-color: transparent;
	}

	.title-header {
		font-size: 22px;
		color: white;

		padding-top: 10px;
		padding-bottom: 10px;
	}

	.footer-text-info {
		font-size: 13px;
		line-height: 1.2;

		margin: 5px;
	}

	.footer-text-link {
		font-size: 13px;
		text-decoration: none;
		color: var(--colorPrimaryBlueFooter);
	}

	.footer-text-link:hover {
		color: var(--colorPrimaryBlueFooter);
	}

	.f-bottom-left {
		width: 100%;
		height: auto;

		display: flex;
		flex-direction: row;
		justify-content: left;
		align-items: center;
	}

	.div-bootom-right {
		width: 100%;
		height: auto;

		display: flex;
		flex-direction: row;
		justify-content: left;
		align-items: center;
	}

	.footer-social-links {
		display: flex;
		flex-direction: row;

		margin-top: 10px;
	}

	.footer-fab-icon {
		width: auto;
		height: 25px;

		background-color: #FFFFFF;
		padding: 4px;

		margin-left: 4px;
		margin-right: 4px;

		border-radius: 50px;
	}

	.footer-fab-icon:hover {
		cursor: pointer;
		background-color: var(--colorPrimaryBlueFooter);
	}

	.footer-fab-list-image {
		width: auto;
		height: 25px;

		background-color: #FFFFFF;
		padding: 5px;

		border-radius: 50px;
	}
}