.navbar-2-dark-bg {
  /** dimen nav default */
  --navWidth: 100%;
  --navHeight: 70px;

  /** dimen navigation mobile screen */
  --navWidthMobileScreen: 100%;
  --navHeightMobileScreen: 45px;

  width: var(--navWidth);
  height: var(--navHeight);
  background-color: black;
}

@media only screen and (min-width : 320px) and (max-width : 480px) {
  .navbar-2-dark-bg {
    /** dimen nav default */
    --navHeight: var(--navHeightMobileScreen);
  
    width: var(--navWidth);
    height: var(--navHeight);
    background-color: black;
  }
}

@media only screen and (min-width : 481px) and (max-width : 768px) {
  .navbar-2-dark-bg {
    /** dimen nav default */
    --navHeight: var(--navHeightMobileScreen);
  
    width: var(--navWidth);
    height: var(--navHeight);
    background-color: black;
  }
}

@media only screen and (min-width : 769px) and (max-width : 1024px) {
  .navbar-2-dark-bg {
    /** dimen nav default */
    --navHeight: 70px;
  
    width: var(--navWidth);
    height: var(--navHeight);
    background-color: black;
  }
}

@media only screen and (min-width : 1025px) and (max-width : 1200px) {
  .navbar-2-dark-bg {
    /** dimen nav default */
    --navHeight: 70px;
  
    width: var(--navWidth);
    height: var(--navHeight);
    background-color: black;
  }
}

@media only screen and (min-width : 1201px) {
  .navbar-2-dark-bg {
    /** dimen nav default */
    --navHeight: 70px;
  
    width: var(--navWidth);
    height: var(--navHeight);
    background-color: black;
  }
}

/**
  320px — 480px: Mobile devices
  481px — 768px: iPads, Tablets
  769px — 1024px: Small screens, laptops
  1025px — 1200px: Desktops, large screens
  1201px and more —  Extra large screens, TV
 */


/*
@media only screen and (min-width : 320px) and (max-width : 480px) {
        
}

@media only screen and (min-width : 481px) and (max-width : 768px) {
        
}

@media only screen and (min-width : 769px) and (max-width : 1024px) {
        
}

@media only screen and (min-width : 1025px) and (max-width : 1200px) {
        
}

@media only screen and (min-width : 1201px) {
        
}
*/