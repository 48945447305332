.detail-portofolio-section {
  width: 100%;
  height: auto;

  /** set background black */
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.detail-portofolio-image-slider {
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: black;
  /** display flex untuk image slider*/
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /** set margin top untuk navbar header */
  padding-top: 0px;
}

.detail-portofolio-container {
  position: relative;

  width: 100%;
  height: 100%;

  overflow: hidden;
  /** set scale zoom out untuk image slider*/
  /** transform: scale(0.9); */
  /** rounded corner bg */
  border-radius: 0px;
  background-color: transparent;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.detail-portofolio-section .header {
  width: 100%;
  
  display: flex;
  flex-direction: column;
  justify-content: center;

  background-color: white;
}

.detail-portofolio-description {
  margin-left: 10%;
  margin-right: 10%;

  margin-top: 20px;
  margin-bottom: 20px;
  
  background-color: transparent;
}

.detail-portofolio-nav-info-header {
  height: auto;
  background-color: white;
  /** center container title text */
  display: flex;
  justify-content: left;
  align-items: center;
}

.detail-portofolio-nav-info-header a {
  font-size: 16px;
  color: black;

  margin-top: 10px;

  background-color: transparent;
}

.loading-page-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
}

.loading-page-title {
  font-size: 22px;
  color: white;
  animation-name: fade1;
  animation-duration: 1.5s;
  transition: all .5s;
  -moz-transition: all .5s;
  -webkit-transition: all .5s;
  -o-transition: all .5s;
  -ms-transition: all .5s;
  justify-content: center;
}

.loading-page-sub-title {
  font-size: 16px;
  color: white;
  animation-name: fade2;
  animation-duration: 1.5s;
  transition: all .5s;
  -moz-transition: all .5s;
  -webkit-transition: all .5s;
  -o-transition: all .5s;
  -ms-transition: all .5s;
  justify-content: center;
}

.fadeloadingpage {
  -webkit-animation-delay: 4.5s;
  -moz-animation-delay: 4.5s;
  animation-delay: 4.5s;
}

.detail-portofolio-text-description {
  font-size: 13px;
  color: black;
}

.detail-portofolio-content-container {
  background-color: white;
  padding-bottom: 50px;
}

@keyframes fade1 {
  from {
    opacity: 0.4;
  }

  to {
    opacity: 1;
  }
}

@keyframes fade2 {
  from {
    opacity: 0.1;
  }

  to {
    opacity: 1;
  }
}

@keyframes fade-in-down {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

/** responsive media screen */
/**
  320px — 480px: Mobile devices
  481px — 768px: iPads, Tablets
  769px — 1024px: Small screens, laptops
  1025px — 1200px: Desktops, large screens
  1201px and more —  Extra large screens, TV
 */


/*
@media only screen and (min-width : 320px) and (max-width : 480px) {
        
}

@media only screen and (min-width : 481px) and (max-width : 768px) {
        
}

@media only screen and (min-width : 769px) and (max-width : 1024px) {
        
}

@media only screen and (min-width : 1025px) and (max-width : 1200px) {
        
}

@media only screen and (min-width : 1201px) {
        
}
*/

@media only screen and (min-width : 320px) and (max-width : 480px) {
  .detail-portofolio-image-slider {
    /** set margin top untuk navbar header */
    padding-top: 0px;
  }

  .detail-portofolio-description {
    margin-left: 20px;
    margin-right:  20px;
    margin-top: 20px;
    margin-bottom: 20px;
    background-color: transparent;
  }

  .detail-portofolio-nav-info-header a {
    font-size: 14px;
    color: black;
  }

  .detail-portofolio-text-description {
    font-size: 14px;
    color: black;
  }
  
  .loading-page-title {
    font-size: 22px;
    color: white;
  }
  
  .loading-page-sub-title {
    font-size: 16px;
    color: white;
  }

  .detail-portofolio-content-container {
    background-color: white;
    margin-top: 10px;
  }
  
}

@media only screen and (min-width : 481px) and (max-width : 768px) {
  .detail-portofolio-image-slider {
    /** set margin top untuk navbar header */
    padding-top: 0px;
  }

  .detail-portofolio-description {
    margin-left: 40px;
    margin-right: 40px;
    margin-top: 20px;
    margin-bottom: 20px;
    background-color: transparent;
  }

  .detail-portofolio-nav-info-header a {
    font-size: 14px;
    color: black;
  }

  .detail-portofolio-text-description {
    font-size: 14px;
    color: black;
  }
  
  .loading-page-title {
    font-size: 22px;
    color: white;
  }
  
  .loading-page-sub-title {
    font-size: 16px;
    color: white;
  }
}

@media only screen and (min-width : 769px) and (max-width : 1024px) {
  .detail-portofolio-image-slider {
    /** set margin top untuk navbar header */
    padding-top: 0px;
  }

  .detail-portofolio-description {
    margin-left: 50px;
    margin-right: 50px;
    margin-top: 20px;
    margin-bottom: 20px;
    background-color: transparent;
  }

  .detail-portofolio-nav-info-header a {
    font-size: 14px;
    color: black;
  }

  .detail-portofolio-text-description {
    font-size: 14px;
    color: black;
  }
  
  .loading-page-title {
    font-size: 22px;
    color: white;
  }
  
  .loading-page-sub-title {
    font-size: 16px;
    color: white;
  }
}

@media only screen and (min-width : 1025px) and (max-width : 1200px) {
  .detail-portofolio-image-slider {
    /** set margin top untuk navbar header */
    padding-top: 0px;
  }

  .detail-portofolio-description {
    margin-left: 50px;
    margin-right: 50px;
    margin-top: 20px;
    margin-bottom: 20px;
    background-color: transparent;
  }

  .detail-portofolio-nav-info-header a {
    font-size: 14px;
    color: black;
  }

  .detail-portofolio-text-description {
    font-size: 14px;
    color: black;
  }
  
  .loading-page-title {
    font-size: 72px;
    color: white;
  }
  
  .loading-page-sub-title {
    font-size: 28px;
    color: white;
  }
}

@media only screen and (min-width : 1201px) {
  .detail-portofolio-image-slider {
    /** set margin top untuk navbar header */
    padding-top: 0px;
  }
  
  .detail-portofolio-description {
    margin-left: 50px;
    margin-right: 50px;
    margin-top: 20px;
    margin-bottom: 20px;
    background-color: transparent;
  }

  .detail-portofolio-nav-info-header a {
    font-size: 14px;
    color: black;
  }

  .detail-portofolio-text-description {
    font-size: 14px;
    color: black;
  }
  
  .loading-page-title {
    font-size: 82px;
    color: white;
  }
  
  .loading-page-sub-title {
    font-size: 32px;
    color: white;
  }
}